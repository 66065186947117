import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import ImeiIcon from "../../../images/icon-phone-imei.svg";
import { TextLight, TextMedium } from "../../../base/BaseText";
import BaseSeparatedInput from "../../../base/BaseSeparatedInput/BaseSeparatedInput";
import BaseLink from "../../../base/BaseLink/BaseLink";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { verifyDeviceImei, initiateChatForImei } from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import generateUniqueId from "../../../helpers/generateUniqueId";

const VerifyImei = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imei, setImei] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const handleSubmitImei = () => {
    dispatch(verifyDeviceImei(imei));
    setDisabled(true);
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const handleDontKnowImei = () => {
    dispatch(initiateChatForImei());
  }

  const shouldntSubmit = disabled || isEmpty(imei) || imei.length < 6;

  return (
    <BaseCard>
      <BaseIcon icon={ImeiIcon} name="IMEI" center />
      <BaseLabel>
        <Trans i18nKey="VerifyImei.Title">
          <TextLight>
            Please enter <TextMedium> LAST 6 digits</TextMedium>
            <br /> of your device's <TextMedium> IMEI number</TextMedium>
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseSeparatedInput
        name={`ImeiVerify-${uniqueId}`}
        value={imei}
        length={6}
        onChange={setImei}
        disabled={disabled}
        onEnterPress={shouldntSubmit ? () => {} : handleSubmitImei}
        autoFocus
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmitImei}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
      <BaseLink
        className="text-center my-3 text-default"
        disabled={disabled}
        onClick={handleDontKnowImei}
      >
        {t("VerifyImei.DontKnowLink")}
      </BaseLink>
    </BaseCard>
  );
};

export default VerifyImei;
