import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../base/BaseCard/BaseCard";
import BaseIcon from "../../base/BaseIcon/BaseIcon";
import NricIcon from "../../images/icon-nric.svg";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseSeparatedInput from "../../base/BaseSeparatedInput/BaseSeparatedInput";
import { TextBold, TextLight } from "../../base/BaseText";
import { validateNric } from "./state/operators";
import { isEmpty } from "../../helpers/formatUtils";
import generateUniqueId from "../../helpers/generateUniqueId";
import { FIELD_IDS } from "../../helpers/constants";

export default function AskNric() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nricCode, setNricCode] = useState();
  const [disabled, setDisabled] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const handleSubmitNric = () => {
    dispatch(validateNric(nricCode));
    setDisabled(true);
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const shouldntSubmit = disabled || isEmpty(nricCode) || nricCode.length !== 4;

  return (
    <div>
      <BaseCard>
        <BaseIcon icon={NricIcon} name="Nric Icon" center />
        <BaseLabel>
          <Trans
            i18nKey="AskNric.Title"
            components={{ light: <TextLight />, bold: <TextBold /> }}
          />
        </BaseLabel>
        <BaseSeparatedInput
          idVal={FIELD_IDS.NRIC}
          value={nricCode}
          length={4}
          onChange={setNricCode}
          onEnterPress={shouldntSubmit ? () => {} : handleSubmitNric}
          disabled={disabled}
          name={`Nric-${uniqueId}`}
        />
        <input id={FIELD_IDS.NRIC} type="hidden" className="hidden" value={nricCode}/>
        <BaseButtonGroup>
          <BaseButton
            id={FIELD_IDS.SUBMIT_NRIC}
            name="Submit"
            className="my-8"
            text={t("SubmitButton")}
            onClick={handleSubmitNric}
            disabled={shouldntSubmit}
            clicked={disabled}
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
}
