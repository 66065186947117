import gql from 'graphql-tag'

export const CreateChatAudit = gql`
  mutation createChatAudit($input: CreateChatAudit!) {
    createChatAudit(input: $input) {
      visitorId
      createdAt
      auditLog
      chatAuditId
      expertName
      requestId
    }
  }
`

export const CreateVisitorMutation = gql`
  mutation createVisitor($input: CreateVisitorInput!) {
    createVisitor(input: $input) {
      visitorCognitoId
      visitorId
      userName
      clientId
      languageCode
      browserAgent
      ipAddress
      journeyStatus
      interactionType
      interactionId
      carrierName
      lastUpdatedTime
      chatAssisted
      lastActivity
      caseNumber
      perilType
      chatReason
      startTimestamp
    }
  }
`

export const UpdateVisitorMutation = gql`
  mutation updateVisitor($input: UpdateVisitorInput!) {
    updateVisitor(input: $input) {
      mdn
      visitorCognitoId
      visitorId
      userName
      clientId
      languageCode
      browserAgent
      ipAddress
      journeyStatus
      interactionType
      interactionId
      carrierName
      lastUpdatedTime
      chatAssisted
      lastActivity
      caseNumber
      perilType
      chatReason
    }
  }
`

export const UpdateVisitorTyping = gql`
  mutation updateVisitorTyping($input: UpdateVisitorTypingInput!) {
    updateVisitorTyping(input: $input) {
      conversationId
      endTimestamp
      visitorTyping
    }
  }
`

export const CreateChatRequestMutation = gql`
  mutation createEncryptedChatRequest($input: CreateChatRequestInput!) {
    createEncryptedChatRequest(input: $input) {
      requestId
      visitorId
      taskId
      visitorName
      interactionId
      mdn
      customerId
      skillId
      languageCode
      clientName
      chatReason
      requestStatus
      requestType
      engagementType
      chatWaitTime
      startTimestamp
      caseNumber
      browserAgent
      wrapUpCode
      requestChannel
    }
  }
`

export const UpdateChatRequestMutation = gql`
  mutation updateEncryptedChatRequest($input: UpdateChatRequestInput!) {
    updateEncryptedChatRequest(input: $input) {
      requestId
      taskId
      requestStatus
      endTimestamp
    }
  }
`

// export const UpdateConversationMutation = gql`
//   mutation updateConversation($input: UpdateConversationInput!) {
//     updateConversation(input: $input) {
//       conversationId

//       endTimestamp
//     }
//   }
// `

export const SubscribeToNewConversation = gql`
  subscription onCreateConversation($requestId: ID!) {
    onCreateConversation(requestId: $requestId) {
      conversationId
      agentCognitoId
      requestId
      visitorId
      createdAt
      endTimestamp
    }
  }
`

export const SubscribeToAgentTyping = gql`
  subscription onAgentTyping($conversationId: ID!){
    onAgentTyping(conversationId: $conversationId){
      conversationId
      agentTyping
    }
  }
`

export const SubscribeToNewMessage = gql`
  subscription onCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      messageId
      visitorId
      conversationId
      messageType
      interactionType
      sender
      source
      content
      isSent
      recipient
      isActive
      createdAt
    }
  }
`

export const CreateMessageMutation = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      messageId
      visitorId
      conversationId
      messageType
      interactionType
      sender
      source
      content
      isSent
      recipient
      isActive
      createdAt
      sourceMsgId
      translated
      sourceLang
      targetLang
    }
  }
`

export const SubscribeToEndChat = gql`
  subscription onUpdateChatRequest($requestId: ID!) {
    onUpdateChatRequest(requestId: $requestId) {
      requestId
      requestStatus
      endTimestamp
      isTransferred
      transferredRequestId
      wrapUpCode
    }
  }
`