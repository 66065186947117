import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const contactsReducer = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callUpdateContactsApi(state, action) {
      state = {};
    },
    callUpdateContactsApiSuccess(state, action) {
      state = action.payload;
    },
    callUpdateContactsApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = contactsReducer;
export const {
  resetStore,
  callUpdateContactsApi,
} = actions;
export default reducer;
