import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as ExpandIcon } from "../../images/icon-expand.svg";
import { ReactComponent as CollapseIcon } from "../../images/icon-collapse.svg";
import { TextMedium } from "../BaseText";

const BaseAccordianOption = ({ id, onClick, selected, disabled, hidden = false, ...data }) => {
  const { name, label, options } = data;
  const optionsAvailable = options.length > 0;
  const isSelected = name === selected;
  const [expand, setExpand] = useState(false);
  const handleOptionClick = () => {
    if (!disabled) {
      if (optionsAvailable) setExpand(!expand);
      else onClick({ name, label });
    }
  };

  return (
    <>
      {!hidden &&
        <>
          <div
            id={id}
            className={classNames(
              "app-BaseAccordian__option",
              {
                "app-BaseAccordian__option-expanded":
                  expand && !isSelected,
              },
              {
                "app-BaseAccordian__option-normal":
                  !expand && !isSelected,
              },
              {
                "app-BaseAccordian__option-selected": isSelected,
              },
              {
                "opacity-50 cursor-not-allowed": disabled,
              }
            )}
            onClick={handleOptionClick}
          >
            <div className="app-BaseAccordian__label">
              <TextMedium>{label}</TextMedium>
            </div>
            {optionsAvailable && (
              <div className="app-BaseAccordian__expandBtn">
                {expand ? <CollapseIcon /> : <ExpandIcon />}
              </div>
            )}
          </div>
          {optionsAvailable && (
            <div
              id={`${id}__childOptions`}
              className={`app-BaseAccordian__child-${expand ? "show" : "hide"
                }`}
            >
              {options.map((option, i) => (
                <BaseAccordianOption
                  key={`${id}_${option.name}`}
                  id={`${id}_${option.name}`}
                  onClick={onClick}
                  selected={selected}
                  disabled={disabled}
                  {...option}
                />
              ))}
            </div>
          )}
        </>
      }
    </>
  );
};

BaseAccordianOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

BaseAccordianOption.defaultProps = {
  options: [],
};

const BaseAccordian = ({
  options,
  onClick,
  selected,
  disabled,
  disabledKeys,
  hideDisabledKeys = false
}) => {
  return (
    <div className="app-BaseAccordian">
      {options.map((option, i) => (
        <BaseAccordianOption
          key={`${option.name}_${i}`}
          id={`${option.name}_${i}`}
          selected={selected}
          onClick={onClick}
          disabled={disabled || disabledKeys.includes(option.name)}
          hidden={disabledKeys.includes(option.name) && hideDisabledKeys}
          {...option}
        />
      ))}
    </div>
  );
};

BaseAccordian.defaultProps = {
  disabled: false,
};

BaseAccordian.propTypes = {
  options: PropTypes.array.isRequired,
  disabledKeys: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseAccordian;
