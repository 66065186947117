import React, { useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { TextMedium, TextLight } from "../../../base/BaseText";

import ContractIcon from "../../../images/icon-contract.svg";
import Terms from "./termsConfig";
import { submitUserAcceptanceOnTerms } from "../state/operators/termsAndConditionOperator";
import { isEmpty } from "../../../helpers/formatUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [iAgree, setIAgree] = useState(false);

  const handleSubmitTC = (status) => {
    dispatch(submitUserAcceptanceOnTerms(status));
    setStatus(status);
    setDisabled(true);
  };

  const feeData = useSelector((state) => state.claim.payment.serviceFee);
  const fee = isEmpty(feeData) ? "99" : feeData.TotalAmountWithDiscount || feeData.TotalAmount;
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest;

  return (
    <BaseCard>
      <BaseIcon icon={ContractIcon} name="contract" center />
      <BaseLabel>
        Terms And Conditions of the
        <br /> Screen Repair Service
      </BaseLabel>
      <BaseInformationBox className="h-25 overflow-y-auto">
      {!isInWarranty && 
          <div className="my-5">
            <div className="text-justify">
              • The{" "}
              <TextMedium>
                Screen Repair Fee is ${fee} (inclusive of GST).
              </TextMedium>
            </div>
          </div>
        }
        {Terms.map((term, i) => (
          <div key={i} className="my-5">
            <div className="text-justify">
              <TextLight>{term.content}</TextLight>
            </div>
          </div>
        ))}
      </BaseInformationBox>
      <BaseCheckbox
        className="w-26r"
        id="termsAndConditionCB"
        value={iAgree}
        onChange={() => setIAgree(!iAgree)}
        label="I accept the Screen Repair Service Terms and Conditions"
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("DeclineButton")}
          disabled={disabled}
          onClick={() => handleSubmitTC("Decline")}
          clicked={status === "Decline"}
          className="border-app-border focus:bg-app-border hover:bg-app-border"
        />
        <BaseButton
          className={classNames({ "opacity-25": !iAgree })}
          text={t("AcceptButton")}
          disabled={disabled || !iAgree}
          onClick={() => handleSubmitTC("Accept")}
          clicked={status === "Accept"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default TermsAndConditions;
