import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CloseIcon from "../../images/icon-close-orange.svg";

const BasePopup = ({ show, title, onClose, children, global, contentClassname = "" }) => {
  return (
    <div
      className={classNames(
        "app-BasePopup",
        { "app-BasePopup-hidden": !show },
        { "app-BasePopup-global": global }
      )}
    >
      <div className={`app-BasePopup-content ${contentClassname}`}>
        <div
          className="app-BasePopup-content__icon-close"
          onClick={onClose}
        >
          <img src={CloseIcon} alt="Close Popup" />
        </div>
        <div className="app-BasePopup-content__title">{title}</div>
        {children}
      </div>
    </div>
  );
};

BasePopup.defaultProps = {
  show: false,
  global: false,
};

BasePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  global: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default BasePopup;
