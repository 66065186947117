import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { helpNeededForInquiry } from "../state/operators/generalEnquiryOperator";
import Loader from "../../../components/Loader/Loader";
import Config from "../../Appsync/Config";

const GeneralEquiryOptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState("");
  const faqUrl = `${Config.WPAPIServer.baseUrl}${Config.WPAPIServer.faq}`;

  const handleSubimt = (answer) => {
    setSelection(answer);
    dispatch(helpNeededForInquiry(answer));
    setDisabled(true);
  };

  const Loading = () => (
    <div className="h-30r absolute w-32r">
      <Loader className="mx-auto my-40" />
    </div>
  );

  const closeLoader = async () => {
    setIsLoading(false);
  };

  return (
    <BaseCard className="p-0 pb-8 relative">
      {isLoading && <Loading />}
      <iframe
        src={faqUrl}
        width="100%"
        className="h-30r block relative"
        frameBorder="0"
        allow-scripts="true"
        onLoad={closeLoader}
        title="FAQs"
      />
      <BaseLabel>{t("GeneralEnquiry.Options.Title")}</BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          onClick={() => handleSubimt("No")}
          text={t("NoButton")}
          disabled={disabled}
          clicked={selection === "No"}
        />
        <BaseButton
          onClick={() => handleSubimt("Yes")}
          text={t("YesButton")}
          disabled={disabled}
          clicked={selection === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default GeneralEquiryOptions;
