import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { isIWTermsConfirmed } from "../state/operators/deviceConfirmationOperators";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";

const ConfirmIWTerms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (isConfirm) => {
    const textPrint = isConfirm ? t(`YesButton`) : t(`NoButton`);
    setDisabled(true);
    dispatch(isIWTermsConfirmed(isConfirm, textPrint));
  };

  return (
    <BaseCard>
      <BaseLabel>
        <Trans i18nKey={`IW_Confirmation.Content`}>
          <TextLight>text</TextLight>
        </Trans>{" "}
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          className="px-16"
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          className="px-16"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmIWTerms;
