import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const npsReducer = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callGetSurveyApi(state, action) {
      state = {};
    },
    callGetSurveyApiSuccess(state, action) {
      state.surveyQuestions = action.payload.GetSurveyQuestionsResponse.SurveyQuestions;
    },
    callGetSurveyApiFailure(state, action) {
      state.error = action.payload;
    },
    callSubmitSurveyApi(state, action) {
      state = {};
    },
    callSubmitSurveyApiSuccess(state, action) {
      state.submitResponse = action.payload;
    },
    callSubmitSurveyApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = npsReducer;
export const {
  resetStore,
  callGetSurveyApi,
  callSubmitSurveyApi,
} = actions;
export default reducer;
