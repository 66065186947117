import React from "react";
import { Link } from "react-router-dom";
import BaseLink from "../../base/BaseLink/BaseLink";
import { TextMedium } from "../../base/BaseText";

const Footer = () => {
  const openPrivacyPolicy = () => {
    window.open("https://www.m1.com.sg/-/media/Files/Data%20Protection/M1DataProtectionPolicy.pdf");
  };

  return (
    <footer className="bg-srgray-secondary text-2xl font-DINPro-Regular pt-8">
      <div className="w-4/5 p-2 ml-auto mr-auto text-white py-8 pt-10">
        <div className="text-4xl pb-8">
          <TextMedium>Data Protection Policy</TextMedium>
        </div>
        <p className="pb-8 text-srgray-tertiary">
          By using the services found on this website, you consent to M1
          collecting, using and/or disclosing your personal data in accordance
          with the M1 Data Protection Policy{" "}
          <BaseLink onClick={openPrivacyPolicy}>here</BaseLink>
          .
          <br />
          <br />
        </p>
        <hr className="border-srgray-tertiary"></hr>
        <div className="text-4xl pt-12">
          <TextMedium>Legal Notice</TextMedium>
        </div>
        <br></br>
        <ul className="pb-8 leading-4r">
          <li>
            <Link to="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/terms-of-use" target="_blank">
              Website Terms of Use
            </Link>
          </li>
          <li>
            <Link to="/faq" target="_blank">
              FAQ
            </Link>
          </li>
        </ul>
        <div className="pt-8">
          <div className="text-srgray-tertiary font-DINPro-Light mb-12">
            <p>
              M1 FoneCare+ is the property of M1. All rights reserved. Apple®
              and iPhone® are registered trademarks of Apple Inc. All other
              trademarks, service marks, and product brands that appear on this
              website are not owned by Asurion and are the property of their
              respective owners. M1 is not affiliated with, sponsored by, or
              endorsed by Apple or any of the respective owners of the other
              trademarks, service marks and/or product brands cited.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
