import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  DEVICE,
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
} from "../../../../helpers/constants";
import { setDeviceMakeAndModel, verifyImeiApi } from "../actions/actions";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  endFlow,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { getMakeModelList } from "../../../../actions/cowrapperapi";
import {
  saveDeviceMakeOptions,
  saveDeviceModelOptions,
} from "../reducers/deviceConfirmationReducer";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { setChatReason } from "../../../Appsync/state/reducers";
import { createServiceRequest } from "../../../../actions/serviceRequestApi";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { isEmpty } from "../../../../helpers/formatUtils";

export const proceedForDeviceConfirmation = (text) => (dispatch, getStore) => {
  const { replacedAsset } = getStore().validation.verification;

  let showComponent = isEmpty(replacedAsset)
    ? "confirmDevice"
    : "displayDevices";

  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("DEVICE_CONFIRMATION", "system", { showComponent }),
    ])
  );
};

export const isDeviceMakeAccepted = (deviceMake, deviceModel) => {
  // return (
  //   deviceMake.toUpperCase() === DEVICE.APPLE ||
  //   deviceMake.toUpperCase() === DEVICE.SAMSUNG
  // );
  if (deviceMake && deviceMake.toUpperCase() === "HUAWEI") {
    if (deviceModel && (deviceModel.toUpperCase().indexOf("MATEXS") > -1 || deviceModel.toUpperCase().indexOf("P50POCKET") > -1)
    ) {
      return false;
    }
  }

  if (deviceMake && deviceMake.toUpperCase() === "NOTHING") {
    return false;
  }

  return true;
};

export const isDeviceMakeXiaomi = (deviceMake) => {
  return false;
  // return (
  //   deviceMake.toUpperCase() === DEVICE.XIAOMI
  // );
};

export const isRegisteredDeviceConfirmed = (
  isDeviceRegistered,
  deviceData,
  fromDeviceList = false
) => async (dispatch, getStore) => {
  // const { enrolledAsset } = getStore().validation.verification;
  const { Name: deviceMake } = deviceData.Make;
  const { Name: deviceModel } = deviceData.Model;
  let decision;
  let showComponent;
  const isDeviceAccepted = isDeviceMakeAccepted(deviceMake, deviceModel);
  if (isDeviceRegistered) {
    decision = fromDeviceList
      ? `${deviceData.AssetInstance} ${deviceData.Make.Name} ${deviceData.AssetCatalog.AssetCatalogName}`
      : "Yes";
    showComponent = isDeviceAccepted ? "verifyImei" : "deviceNotEligible";

    if (isDeviceMakeXiaomi(deviceMake)) {
      showComponent = "deviceNotEligible";
    }

    dispatch(setDeviceMakeAndModel(deviceData, deviceMake.toUpperCase() === DEVICE.APPLE || deviceMake.toUpperCase() === DEVICE.SAMSUNG));
    dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));
  } else {
    decision = "No";
    showComponent = "chooseEnrolledDevice";
  }

  dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

  if (showComponent === "verifyImei") {
    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.VERIFY_IMEI.title,
        PROGRESS_DATA.VERIFY_IMEI.count,
        initial
      )
    );
    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.InWarrantyRequest,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
  }

  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent,
      }),
    ])
  );
};

export const submitChosenEnrolledDevice = (deviceData) => async (
  dispatch,
  getStore
) => {
  let decision = "cancel";
  let data;
  let module;

  if (deviceData.imei) {
    decision = `${deviceData.make.Make} ${deviceData.model.PopularName}`;

    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

    // const isDeviceAccepted = isDeviceMakeAccepted(deviceData.make.Make, deviceData.model.Model);
    // const showComponent = isDeviceAccepted
    //   ? "confirmIWTerms"
    //   : "deviceNotEligible";

    let isDeviceAccepted = isDeviceMakeAccepted(deviceData.make.Make, deviceData.model.Model);
    let showComponent = isDeviceAccepted
      ? "confirmIWTerms"
      : "deviceNotEligible";

    if (isDeviceMakeXiaomi(deviceData.make.Make)) {
      isDeviceAccepted = false;
      showComponent = "deviceNotEligible";
    }

    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;

    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.InWarrantyRequest,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }

    if (isDeviceAccepted) {
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };

      const deviceDetails = {
        AssetCatalog: {
          AssetCatalogId: deviceData.model.CatalogId,
          AssetCatalogName: deviceData.model.AssetCatalogName,
        },
        Make: {
          MakeId: deviceData.make.MakeId,
          Name: deviceData.make.Make,
        },
        Model: {
          ModelId: deviceData.model.ModelId,
          Name: deviceData.model.Model,
        },
        IMEI: deviceData.imei,
      };
      dispatch(setDeviceMakeAndModel(deviceDetails, deviceData.make.Make.toUpperCase() === DEVICE.APPLE || deviceData.make.Make.toUpperCase() === DEVICE.SAMSUNG));

    } else {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };
      dispatch(confirmToCancelRequest("", "device-ineligible"));
    }
  } else {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));
    module = "ENQUIRY_OPTIONS";
    data = {};
  }

  dispatch(addJourneyMessages([createMessage(module, "system", data)]));
};

export const verifyDeviceImei = (imei) => async (dispatch, getStore) => {
  // Before API call
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    imeiVerification,
    selectedAsset: { AssetInstance: assetInstance },
  } = getStore().claim.deviceConfirmation;
  const imeiVerificationFailedAttempt = imeiVerification
    ? imeiVerification.IMEIVerificationFailedAttempt
    : 0;
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", imei)]));
  await dispatch(
    updateApiProgress(API_PROGRESS.VERIFY_IMEI, 40, COMPONENTS.VERIFY_IMEI, 0)
  );

  // API call
  const response = await dispatch(
    verifyImeiApi({
      imei,
      cacheId: CacheId,
      assetInstance,
      imeiVerificationFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(response)) return;

  const {
    VerificationOutcome,
    IMEIVerificationFailedAttempt,
  } = response.VerifyIMEIResults;

  // After API call
  await dispatch(
    updateApiProgress(
      API_PROGRESS.VERIFY_IMEI_SUCCESS,
      100,
      COMPONENTS.VERIFY_IMEI,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  if (VerificationOutcome) {
    dispatch(updateVisitor({ lastActivity: ACTIVITY.VERIFY_IMEI }));

    dispatch(
      addJourneyMessages([
        createMessage("DEVICE_CONFIRMATION", "system", {
          showComponent: "confirmIWTerms",
        }),
      ])
    );
  } else {
    if (IMEIVerificationFailedAttempt < 3) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImei",
          }),
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "verifyImei",
          }),
        ])
      );
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImeiTerminate",
          }),
        ])
      );
      await dispatch(setChatReason("Invalid Imei"));
      dispatch(udpateChatInputVisibility(true));
    }
  }
};

export const getDeviceMakeModelOptions = (makeId) => async (
  dispatch,
  getStore
) => {
  const { ClientId } = getStore().session.sessionData.Configurations;
  if (makeId) {
    const models = await dispatch(getMakeModelList(ClientId, makeId));
    await dispatch(saveDeviceModelOptions(models));
  } else {
    const makes = await dispatch(getMakeModelList(ClientId));
    await dispatch(saveDeviceMakeOptions(makes));
  }
  return;
};

export const handleDeviceHoldClose = (text) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));
  dispatch(endFlow());
};

export const initiateChatForImei = () => async (dispatch) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "VerifyImei.DontKnowLink",
      }),
    ])
  );
  await dispatch(setChatReason("I Don't Know My IMEI"));
  dispatch(udpateChatInputVisibility(true));
};

export const initiateIMEIChat = () => async (dispatch) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "VerifyImei.DontKnowLink",
      }),
    ])
  );
  await dispatch(setChatReason("I Don't Know My IMEI"));
  dispatch(udpateChatInputVisibility(true));
};

export const isIWTermsConfirmed = (isIWTermsConfirmed, textPrint) => async (
  dispatch,
  getStore
) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (isIWTermsConfirmed) {
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.TROUBLESHOOT.title,
        PROGRESS_DATA.TROUBLESHOOT.count,
        initial
      )
    );
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.AnswerFollowingQuestion",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.DeviceWillBeDiagnosed",
        }),
        createMessage("TROUBLESHOOT", "system", {
          showComponent: "CameraWorking",
        }),
      ])
    );
  } else {
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.IWTermsCancel",
        }),
      ])
    );
    dispatch(confirmToCancelRequest("", "Cancel-IW-Terms page", true));
  }
};
