import { callAssetsApi } from "../reducers/cowrapperReducer";
import { callCreateGeneralEnquiryApi } from "../modules/RepairClaim/state/reducers/generalEnquiryReducer";
import { callGetEnrolledServiceFeeApi } from "../modules/RepairClaim/state/reducers/paymentReducer";

export const getMakeModelList = (clientId, makeId = "") => ({
  type: callAssetsApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/assets",
    method: "POST",
    body: {
      GetAssetsMakeModelRequest: {
        ClientId: clientId,
        makeId: makeId,
      },
    },
  },
});

export const createGeneralEnquiry = (
  carrier,
  country,
  language,
  originalQuestion
) => ({
  type: callCreateGeneralEnquiryApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/generalinquiry",
    method: "POST",
    body: {
      GeneralInquiryRequest: {
        Carrier: carrier,
        Country: country,
        Language: language,
        OriginalQuestion: originalQuestion,
        InquiryDate: new Date(),
      },
    },
  },
});

export const getEnrolledServiceFee = (clientId, channelId, clientName, interactionLineId, mdn) => ({
  type: callGetEnrolledServiceFeeApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/servicefee",
    method: "POST",
    body: {
      GetServiceFeeRequest: {
        ClientId: clientId,
        ClientChannelId: channelId,
        ClientName: clientName,
        InteractionLineId: interactionLineId,
        MobileDeviceNumber: mdn,
      },
    },
  },
});
