import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { initProcessFlow } from "./../actions/initProcessFlow";
import JourneyMsgs from "./../components/JourneyMsgs/JourneyMsgs";
import BaseLoader from "../base/BaseLoader/BaseLoader";
import ApiProgress from "../components/ApiProgress";
import ThankYouNote from "../components/ThankYouNote";
import Chat from "../modules/Appsync/Chat/Chat";
import FlowHeader from "./FlowHeader";
import FindingAgent from "../components/FindingAgent";
import { CHAT_STATUS } from "../modules/Appsync/Chat/constants";
import { isEmpty } from "../helpers/formatUtils";
import { updateChatConnect } from "../modules/Appsync/state/operators";
import AskUsIcon from "../images/icon-ask-us.svg";
import ErrorNote from "../components/ErrorNote";
import updateVisitor from "../modules/Appsync/updateVisitor";
import { ONLINE_JOURNEY_STATUS_LIST } from "../helpers/constants";

const Flow = ({ onToggleFlowWindow }) => {
  const dispatch = useDispatch();
  const chatData = useSelector((state) => state.chat);
  const journeyMessageData = useSelector((state) => state.journeyMessages);
  const progress = journeyMessageData.flowProgress.percentage;
  const isInProgress = progress > 0 && progress < 100;
  const visitor = useSelector((state) => state.chat.visitor);
  const {
    apiProgress: apiProgressData,
    showThankYouNote,
    showErrorNote,
  } = journeyMessageData;
  const [showJourneyMsgs, setShowJourneyMsgs] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const startChat = chatData.showChatInput;
  const chatStatus = chatData.status;

  useEffect(() => {
    const initializeProcess = async () => {
      await dispatch(initProcessFlow());
      setShowLoader(false);
      setShowJourneyMsgs(true);
    };
    initializeProcess();
  }, []);

  const closeFlowWindow = async () => {
    if (startChat && chatStatus === CHAT_STATUS.CONNECTED) {
      dispatch(updateChatConnect(false));
    } else {
      if (visitor) {
        dispatch(
          updateVisitor({
            journeyStatus: ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
          })
        );
      }
      onToggleFlowWindow();
    }
  };

  return (
    <div className="app__flow-container w-full lg:mr-4 lg:mb-4 lg:max-w-4xl lg:h-60r">
      {showErrorNote ? (
        <ErrorNote onClose={closeFlowWindow} />
      ) : showThankYouNote ? (
        <ThankYouNote onClose={closeFlowWindow} />
      ) : (
        <>
          <FlowHeader onClose={closeFlowWindow} />
          <div
            id="app-Flow__body"
            className={classNames(
              "app-flow__body",
              {
                "app-flow__body-chat-input":
                  startChat && !isInProgress,
              },
              {
                "app-flow__body-progress-header":
                  isInProgress && !startChat,
              },
              {
                "app-flow__body-chat-and-progress":
                  isInProgress && startChat,
              }
            )}
          >
            {showLoader ? (
              <BaseLoader icon={AskUsIcon} text="Initializing" />
            ) : (
              showJourneyMsgs && <JourneyMsgs />
            )}
            <ApiProgress {...apiProgressData} />
            {(!startChat || chatStatus !== CHAT_STATUS.CONNECTED) && (
              <div className="py-8"></div>
            )}
            {startChat &&
              (isEmpty(chatStatus) || chatStatus !== CHAT_STATUS.CONNECTED) && (
                <FindingAgent />
              )}
          </div>
          {startChat && (
            <Chat visitorDetails={visitor} closeChat={onToggleFlowWindow} />
          )}
        </>
      )}
    </div>
  );
};

export default Flow;
