import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import PickUpAddressIcon from "../../../images/icon-address.svg";
import DeliveryAddressIcon from "../../../images/icon-tracking.svg";
import { TextLight } from "../../../base/BaseText";
import { confirmationOfAddress } from "../state/operators/scheduleOperator";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";

const ConfirmAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const whichUpper = isPickup ? "Pick-up" : "Return";
  const which = whichUpper.toLowerCase();
  const addressIcon = isPickup ? PickUpAddressIcon : DeliveryAddressIcon;
  const { Address1, Address2, Address3, City, PostalCode } = isPickup
    ? scheduleData.selectedPickupAddress
    : scheduleData.selectedDeliveryAddress;
  const address = `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmAdress = (status) => {
    setConfirmationStatus(status);
    setDisabled(true);
    dispatch(confirmationOfAddress(status, isPickup));
  };

  return (
    <BaseCard>
      <BaseIcon icon={addressIcon} name="Tracking" center />
      <BaseLabel>
        <Trans i18nKey="ConfirmAddress.Title">
          <TextLight>
            Please confirm the {{ which }}
            <br /> address of your device
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseTable title={t(`ConfirmAddress.TableTitle.${whichUpper}`)} verticle>
        <BaseTR>
          <BaseTH>{`${whichUpper} Address`}</BaseTH>
          <BaseTD>{address}</BaseTD>
        </BaseTR>
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmAdress("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
        />
        <BaseButton
          text={t("ConfirmButton")}
          onClick={() => handleConfirmAdress("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmAddress.defaultProps = {
  isPickup: true,
};

export default ConfirmAddress;
