import {
  callAgreementsApi,
  setMdn,
  setNames,
  callVerifyAgreementsApi,
  setNric,
} from "./reducers";

export const agreementsApi = ({
  cacheId,
  mdn,
  firstName = "User",
  lastName = "user",
  customerName,
  AgreementFailedAttempt = 0,
  NameFailedAttempt,
  correlationid,
}) => ({
  type: callAgreementsApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v2/findagreement`,
    method: "POST",
    body: {
      FindAgreementsParameters: {
        AgreementFailedAttempt,
        NameFailedAttempt,
        CustomerName: customerName,
        ...(firstName && { FirstName: firstName }),
        ...(lastName && { LastName: lastName }),
        MobileDeviceNumber: mdn,
        SessionId: cacheId,
        ...(correlationid && { correlationid }),
      },
    },
  },
});

export const verifyAgreementApi = ({
  cacheId,
  mdn,
  verificationFailedAttempt = 0,
  nricCode,
  isAgentVerified
}) => ({
  type: callVerifyAgreementsApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: '/incidentidentification/v2/verify',
    method: 'POST',
    body: {
      VerifyAgreementsParameters: {
        MobileDeviceNumber: mdn || "",
        FailedAttempt: verificationFailedAttempt,
        PassportNumber: nricCode,
        CustomerVerificationMethod: "PASSNBR",
        SessionId: cacheId,
        isAgentVerified,
        isNameValidationRequired: false,
      },
    },
  },
});

export const setMdnData = (mdn) => ({
  type: setMdn.toString(),
  payload: { mdn },
});

export const setNamesData = (firstName, lastName) => ({
  type: setNames.toString(),
  payload: { firstName, lastName },
});

export const setNricData = (code) => ({
  type: setNric.toString(),
  payload: { nric: code },
});
