import React from "react";
import PropTypes from "prop-types";
import GeneralEnquiryOptions from "./GeneralEnquiryOptions";
import IsExistingCustomer from "./IsExistingCustomer";
import ProvideEnquiryDetails from "./ProvideEnquiryDetails";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "GeneralEnquiryOptions" && <GeneralEnquiryOptions />}
      {showComponent === "IsExistingCustomer" && <IsExistingCustomer />}
      {showComponent === "ProvideEnquiryDetails" && <ProvideEnquiryDetails />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
