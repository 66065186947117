import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium } from "../../../base/BaseText";

import ContactConfig from "./contactConfirmationConfig";
import { submitConfirmationOfContactDetails } from "../state/operators/contactsOperator";

const ConfirmContactDetails = ({ code }) => {
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.validation.verification);

  const handleSubmitConfirmation = (status) => {
    setDisabled(true);
    setStatus(status);
    dispatch(submitConfirmationOfContactDetails(status, code));
  };

  return (
    <BaseCard>
      <BaseIcon icon={ContactConfig[code].icon} name="Contact" center />
      <BaseLabel>
        <Trans i18nKey={`Contacts.${code}.Title`}>{ContactConfig[code].title}</Trans>
      </BaseLabel>
      <BaseLabel>
        <TextMedium>
          {code === "ConfirmMobileNumber"
            ? `${data.enrolledAsset.CountryCallingCode} ${data.PhoneNumber}`
            : data.EmailAddress}
        </TextMedium>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          disabled={disabled}
          clicked={status === "No"}
          onClick={() => handleSubmitConfirmation("No")}
          className="px-16"
        />
        <BaseButton
          className="px-16"
          text={t("YesButton")}
          disabled={disabled}
          clicked={status === "Yes"}
          onClick={() => handleSubmitConfirmation("Yes")}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmContactDetails.defaultProps = {
  code: "ConfirmMobileNumber",
};

ConfirmContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ConfirmContactDetails;
