import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const paymentReducer = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setCCDetails(state, action) {
      state.ccDetails = action.payload;
    },
    setPaymentData(state, action) {
      state.isCardValid = action.payload;
    },
    setPaymentVerificationAttempt(state, action) {
      state.paymentVerificationAttempt = action.payload;
    },
    callGetServiceFeeApi(state, action) {
      state.serviceFee = {};
    },
    callGetServiceFeeApiSuccess(state, action) {
      // if (Array.isArray(action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee)) {
      //   state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee[0];
      // } else {
      //   state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee;
      // }     
      const {
        GetServiceFeeResponse: { ServiceFee },
      } = action.payload;

      if (Array.isArray(action.payload.GetServiceFeeResponse.ServiceFee.ServiceFee)) {
        let ServiceFeeData = {...ServiceFee?.ServiceFee[0]}
        let FeesData = {...ServiceFeeData.Fees};
        state.serviceFee = { ...ServiceFeeData, ...FeesData};
      } else {
        let ServiceFeeData = {...ServiceFee?.ServiceFee}
        let FeesData = {...ServiceFeeData.Fees};
        state.serviceFee = { ...ServiceFeeData, ...FeesData};
      } 
    },
    callGetServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetPciTokenApi(state, action) {
      state.pciToken = {};
    },
    callGetPciTokenApiSuccess(state, action) {
      state.pciToken = action.payload;
    },
    callGetPciTokenApiFailure(state, action) {
      state.error = action.payload;
    },
    callProcessTransactionApi(state, action) {
      state.processTransaction = {};
    },
    callProcessTransactionApiSuccess(state, action) {
      state.processTransaction = action.payload;
    },
    callProcessTransactionApiFailure(state, action) {
      state.error = action.payload;
    },
    callChargeOrderApi(state, action) {
      state.chargeOrder = {};
    },
    callChargeOrderApiSuccess(state, action) {
      state.chargeOrder = action.payload;
    },
    callChargeOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    callSubmitOrderApi(state, action) {
      state.submitOrder = {};
    },
    callSubmitOrderApiSuccess(state, action) {
      state.submitOrder = action.payload;
    },
    callSubmitOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetEnrolledServiceFeeApi(state, action) {
      state.serviceFee = {};
    },
    callGetEnrolledServiceFeeApiSuccess(state, action) {
      state.serviceFee = action.payload.GetServiceFeeResponse.ServiceFees.find(
        (f) => f.IncidentType === "SCREEN REPAIR"
      ).Fees;
    },
    callGetEnrolledServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = paymentReducer;
export const {
  resetStore,
  setPaymentData,
  setPaymentVerificationAttempt,
  callGetServiceFeeApi,
  callGetPciTokenApi,
  setCCDetails,
  callProcessTransactionApi,
  callChargeOrderApi,
  callSubmitOrderApi,
  callGetEnrolledServiceFeeApi
} = actions;
export default reducer;
