import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const termsAndConditionsReducer = createSlice({
  name: "termsAndConditions",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callProcessIncidentApi(state, action) {
      state.processIncident = action.payload || {};
    },
    callProcessIncidentApiSuccess(state, action) {
      state.processIncident = action.payload;
    },
    callProcessIncidentApiFailure(state, action) {
      state.error = action.payload;
    },
    setCustomerAgreementStatus(state, action) {
      state.isCustomerAgreed = action.payload;
    },
    callGetFulFillmentApi(state, action) {
      state.getFulFIllment = action.payload || {};
    },
    callGetFulFillmentApiSuccess(state, action) {
      state.getFulFIllment = action.payload;
    },
    callGetFulFillmentApiFailure(state, action) {
      state.error = action.payload;
    },
    callSetFulFillmentApi(state, action) {
      state.getFulFIllment = action.payload || {};
    },
    callSetFulFillmentApiSuccess(state, action) {
      state.getFulFIllment = action.payload;
    },
    callSetFulFillmentApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = termsAndConditionsReducer;
export const {
  resetStore,
  setCustomerAgreementStatus,
  callProcessIncidentApi,
  callGetFulFillmentApi,
  callSetFulFillmentApi,
} = actions;
export default reducer;
