import React, { useState } from "react";
import { validateName } from "./state/operators";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import BaseCard from "./../../base/BaseCard/BaseCard";
import BaseIcon from "../../base/BaseIcon/BaseIcon";
import IdCardIcon from "./../../images/icon-id-card.svg";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "./../../base/BaseButton/BaseButtonGroup";
import BaseButton from "./../../base/BaseButton/BaseButton";
import BaseInput from "./../../base/BaseInput/BaseInput";
import { isEmpty } from "../../helpers/formatUtils";
import { FIELD_IDS, REGEX } from "../../helpers/constants";

export default function AskName() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleFirstName = (val) => {
    if (!val.match(REGEX.Text)) {
      setFirstName(val);
    }
  };

  const handleLastName = (val) => {
    if (!val.match(REGEX.Text)) {
      setLastName(val);
    }
  };

  const handleSubmit = () => {
    dispatch(validateName(firstName, lastName));
    setDisabled(true);
  };

  const shouldntSubmit = disabled || isEmpty(firstName) || isEmpty(lastName);

  return (
    <div>
      <BaseCard>
        <BaseIcon icon={IdCardIcon} name="Icon ID Card" center />
        <BaseLabel><Trans i18nKey="AskName.Title" /></BaseLabel>
        <BaseInput
          id={FIELD_IDS.FIRST_NAME}
          placeholder={t('AskName.FirstNamePlaceholder')}
          className="mt-0"
          value={firstName}
          medium
          onChange={(val) => handleFirstName(val.trim())}
          autoFocus
          disabled={disabled}
        />
        <BaseInput
          id={FIELD_IDS.LAST_NAME}
          placeholder={t('AskName.LastNamePlaceholder')}
          className="mt-0"
          value={lastName}
          medium
          onChange={(val) => handleLastName(val.trim())}
          disabled={disabled}
          onEnterPress={shouldntSubmit ? () => {} : handleSubmit}
        />
        <BaseButtonGroup>
          <BaseButton
            id={FIELD_IDS.SUBMIT_NAME}
            name="Submit"
            text={t('SubmitButton')}
            onClick={handleSubmit}
            disabled={shouldntSubmit}
            clicked={disabled}
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
}
