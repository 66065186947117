import React from "react";
import { useParams } from "react-router-dom";
import { WORDPRESS_CONTENTS } from "../helpers/constants";
import Config from "../modules/Appsync/Config";

const WordpressContent = () => {
  const { content } = useParams();
  let contentPath = "";
  let contentTitle = "";

  switch (content) {
    case WORDPRESS_CONTENTS.FAQ.PATH:
      contentPath = Config.WPAPIServer.faq;
      contentTitle = WORDPRESS_CONTENTS.FAQ.TITLE;
      break;
    case WORDPRESS_CONTENTS.PREPARE.PATH:
      contentPath = Config.WPAPIServer.prepare;
      contentTitle = WORDPRESS_CONTENTS.PREPARE.TITLE;
      break;
    case WORDPRESS_CONTENTS.TERMS_AND_CONDITIONS.PATH:
      contentPath = Config.WPAPIServer.termsAndCondition;
      contentTitle = WORDPRESS_CONTENTS.TERMS_AND_CONDITIONS.TITLE;
      break;
    case WORDPRESS_CONTENTS.TERMS_OF_USE.PATH:
      contentPath = Config.WPAPIServer.termsOfUse;
      contentTitle = WORDPRESS_CONTENTS.TERMS_OF_USE.TITLE;
      break;
    default:
      window.location.href = window.location.origin;
      break;
  }

  return (
    <div>
      <iframe
        src={`${Config.WPAPIServer.baseUrl}${contentPath}`}
        width="100%"
        className="block h-screen w-full"
        frameBorder="0"
        title={contentTitle}
      />
    </div>
  );
};

export default WordpressContent;
