import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const cowrapperReducer = createSlice({
  name: "cowrapper",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callAssetsApi(state, action) {
      state.assets = action.payload || {};
    },
    callAssetsApiSuccess(state, action) {
      console.log(action.payload);
      return state;
    },
    callAssetsApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = cowrapperReducer;
export const { callAssetsApi } = actions;
export default reducer;
