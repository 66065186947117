import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseLabel from "../../base/BaseLabel";
import BaseInformationBox from "../../base/BaseInformationBox";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BasePopup from "../../base/BasePopup/BasePopup";
import { validateMdn } from "./state/operators";
import { TextMedium } from "../../base/BaseText";
import { isEmpty } from "../../helpers/formatUtils";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "../Appsync/Config";
import { FIELD_IDS } from "../../helpers/constants";

const AskMdn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [freezeWindow, setFreezeWindow] = useState(false);

  const reCaptchaRef = React.createRef();
  const greCaptchaObject = window.grecaptcha;
  let token = "";

  const handleMdnChange = (value) => {
    if (!isNaN(value.trim())) setMdn(value.trim());
  };

  const submitMdn = async () => {
    if (process.env?.REACT_APP_ENV !== 'QA') {
      try {
        const token = await reCaptchaRef.current.executeAsync();
        if (!token) {
          greCaptchaObject.execute();
        } else {
          dispatch(validateMdn(mdn));
          setFreezeWindow(true);
        }
      }
      catch(e) {
        console.log("exception in captcha execution:", e);
      }
    } else {
      dispatch(validateMdn(mdn));
      setFreezeWindow(true);
    }
  };

  const verifyCaptcha = (res) => {
    token = res;
    console.log(`captcha token is ${token}`);
  };

  const shouldSubmit = freezeWindow || isEmpty(mdn) || mdn.length !== 8;

  return (
    <>
      <div className="app-AskMdn">
        <BaseCard>
          <BaseLabel>
            <div className="text-default -mx-3">
              <Trans i18nKey="AskMdn.Label">
                For an uninterrupted experience, <br />
                please
                <span
                  className="text-app-primary font-semibold cursor-pointer"
                  onClick={() => setShowPopup(true)}
                >
                  check that you are eligible to access this <br />
                  Screen Repair Service{" "}
                </span>
                and please have ready:
              </Trans>
            </div>
          </BaseLabel>
          <BaseInformationBox>
            <ul className="list-disc list-inside">
              <li>
                <Trans i18nKey="AskMdn.Information.1">
                  Your <TextMedium>National ID/NRIC/FIN number</TextMedium>
                </Trans>
              </li>
              <li>
                <Trans i18nKey="AskMdn.Information.2">
                  Device <TextMedium>make, model</TextMedium> and{" "}
                  <TextMedium>IMEI</TextMedium> number
                </Trans>
              </li>
            </ul>
          </BaseInformationBox>
          <BaseInput
            id={FIELD_IDS.MDN}
            value={mdn}
            placeholder={t("AskMdn.Placeholder")}
            onChange={handleMdnChange}
            small
            maxLength={8}
            disabled={freezeWindow}
            autoFocus
            onEnterPress={shouldSubmit ? () => {} : submitMdn}
          />
          <BaseButtonGroup>
            <BaseButton
              id={FIELD_IDS.SUBMIT_MDN}
              name="Submit"
              text={t("AskMdn.LetsBegin")}
              onClick={submitMdn}
              clicked={freezeWindow}
              disabled={shouldSubmit}
              focused
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
      {showPopup && (
        <BasePopup
          show={true}
          title={t("AskMdn.Popup.Title")}
          onClose={() => setShowPopup(false)}
        >
          <ul className="list-disc list-inside font-DINPro-Light text-xl">
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.1" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.2" />
            </li>
            <li className="my-4 ml-8">
              <Trans i18nKey="AskMdn.Popup.2_1" />
            </li>
            <li className="my-4 ml-8">
              <Trans i18nKey="AskMdn.Popup.2_2" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.3" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.4" />
            </li>
          </ul>
        </BasePopup>
      )}

      {process.env?.REACT_APP_ENV !== 'QA' &&
        <div className="hidden">
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={Config.Captcha.SiteKey}
            onChange={verifyCaptcha}
            size="invisible"
            grecaptcha={greCaptchaObject}
          />
        </div>
      }
      
    </>
  );
};

export default AskMdn;
