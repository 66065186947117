import React from "react";
import PropTypes from "prop-types";
import RequestForSurvey from "./RequestForSurvey";
import SubmitRating from "./SubmitRating";
import SubmitFeedback from "./SubmitFeedback";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "RequestForSurvey" && <RequestForSurvey />}
      {showComponent === "SubmitRating" && <SubmitRating />}
      {showComponent === "SubmitFeedback" && <SubmitFeedback />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
