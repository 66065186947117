import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import { TextLight, TextMedium } from "../../../base/BaseText";
import BaseInformationBox from "../../../base/BaseInformationBox";

import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
import { changeDateTimeSchedule } from "../state/operators/scheduleOperator";
import { getDeliveryDates, isMondayOrFriday } from "../../../helpers/dateUtils";
import { isEmpty } from "../../../helpers/formatUtils";
import { DEVICE } from "../../../helpers/constants";

const ChangeDateTime = ({ isPickup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let dateOptions = [];
  const [slotOptions, setSlotOptions] = useState([]);
  const which = isPickup ? "Pick-up" : "Return";

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState("");

  const assetData = useSelector(
    (state) => state?.validation?.verification?.enrolledAsset
  );
  const deviceMake = assetData?.Make?.Name;
  const isDeviceMakeHuaweiOrXiaomi = (makeName) => {
    if (makeName) {
      return (
        makeName.toUpperCase() === DEVICE.HUAWEI ||
        makeName.toUpperCase() === DEVICE.XIAOMI
      );
    }
    return false;
  };

  const pickupOptions = useSelector(
    (state) => state.claim.schedule.pickupOptions
  );
  const pickupDate = useSelector(
    (state) => state.claim.schedule.selectedPickupDate
  );
  const pickupSlot = useSelector(
    (state) => state.claim.schedule.selectedPickupSlot
  );

  const isCampaignTimingEligible = useSelector(
    (state) => state.claim.schedule.isCampaignTimingEligible
  );
  const returnSLA = isCampaignTimingEligible ? "1-2 business days" : "2 business days"

  if (isPickup) {
    let modifiedDateOptions = pickupOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
    dateOptions = modifiedDateOptions.filter(Boolean)
  } else {
    const deliveryOptions = isCampaignTimingEligible 
    ? getDeliveryDates(pickupDate, pickupSlot, true, true) 
    : getDeliveryDates(pickupDate);
    dateOptions = deliveryOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
  }

  const handleDateChange = (dateStr) => {
    setSlot("");
    setDate(dateStr);
    let opt = dateOptions.find((o) => o.id === dateStr);
    setSlotOptions(opt.slots);
  };

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(changeDateTimeSchedule(date, slot, isPickup));
  };

  const cancelChangingDate = () => {
    dispatch(changeDateTimeSchedule(null, null, isPickup));
  };

  const shouldntSubmit = disabled || isEmpty(date) || isEmpty(slot);

  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Trans i18nKey="ChangeDateTime.Title">
          <TextLight>Select New {{ which }} Date and Time</TextLight>
        </Trans>
      </BaseLabel>
      <BaseDropdown
        placeHolder={
          isPickup
            ? t("ChangeDateTime.Input.PickupDate")
            : t("ChangeDateTime.Input.DeliveryDate")
        }
        value={date}
        onChange={handleDateChange}
        options={dateOptions}
        disabled={disabled}
      />
      <BaseDropdown
        placeHolder={
          isPickup
            ? t("ChangeDateTime.Input.PickupSlot")
            : t("ChangeDateTime.Input.DeliverySlot")
        }
        value={slot}
        onChange={setSlot}
        options={slotOptions}
        disabled={disabled || isEmpty(slotOptions)}
        pureArray={true}
      />
      <BaseInformationBox>
        <Trans i18nKey="ChangeDateTime.Information">
          <TextLight>
            Please note that the Screen Repair service will be completed in{" "}
            <TextMedium>{{returnSLA}}</TextMedium> from the date the device is
            picked up.<br/><br/>
          </TextLight>
        </Trans>
        {isPickup &&
          <span style={{ display: "block", color: "red" }}>
            <br/>
            <Trans i18nKey="ChangeDateTime.Information2">
              <TextLight>
                * For Huawei and Xiaomi devices, pick-up is only
                available on Mon or Fri due to a change in repair center
                operating hours.
              </TextLight>
            </Trans>
          </span>
        }
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          text={t("CancelButton")}
          onClick={cancelChangingDate}
          disabled={disabled}
          className="border-app-border focus:bg-app-border hover:bg-app-border"
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeDateTime.defaultProps = {
  isPickup: true,
};

export default ChangeDateTime;
