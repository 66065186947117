import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";

import VeryDissatisfiedIcon from "../../../images/icon-verydissatisfied.svg";
import VeryDissatisfiedActiveIcon from "../../../images/icon-verydissatisfied-active.svg";
import DissatisfiedIcon from "../../../images/icon-dissatisfied.svg";
import DissatisfiedActiveIcon from "../../../images/icon-dissatisfied-active.svg";
import NeutralIcon from "../../../images/icon-neutral.svg";
import NeutralActiveIcon from "../../../images/icon-neutral-active.svg";
import SatisfiedIcon from "../../../images/icon-satisfied.svg";
import SatisfiedActiveIcon from "../../../images/icon-satisfied-active.svg";
import ExcellentIcon from "../../../images/icon-excellent.svg";
import ExcellentActiveIcon from "../../../images/icon-excellent-active.svg";
import { submitRatingFromUser } from "../state/operators/npsOperator";

const SubmitRating = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.claim.survey.surveyQuestions);
  const [disabled, setDisabled] = useState(false);
  const [reaction, setReaction] = useState("Neutral");
  const [rating, setRating] = useState(3);

  const onEmojiChange = (emoji, value) => {
    setReaction(emoji);
    setRating(value);
  };

  const handleSubmit = () => {
    setDisabled(true);
    const question = questions.find(q => q.QuestionType === "CSAT");
    dispatch(submitRatingFromUser(reaction, rating.toString(), question.QuestionId, question.SurveyId));
  };

  const Ratings = {
    "Very Dissatisfied": {
      icon: VeryDissatisfiedIcon,
      activeIcon: VeryDissatisfiedActiveIcon,
      value: 1,
    },
    Dissatisfied: {
      icon: DissatisfiedIcon,
      activeIcon: DissatisfiedActiveIcon,
      value: 2,
    },
    Neutral: {
      icon: NeutralIcon,
      activeIcon: NeutralActiveIcon,
      value: 3,
    },
    Satisfied: {
      icon: SatisfiedIcon,
      activeIcon: SatisfiedActiveIcon,
      value: 4,
    },
    Excellent: {
      icon: ExcellentIcon,
      activeIcon: ExcellentActiveIcon,
      value: 5,
    },
  };

  return (
    <BaseCard>
      <BaseLabel>
        <Trans i18nKey="SubmitRating.Title">
          <TextLight>
            How would you rate your overall <br />
            experience with Screen Repair Service?
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseIcon
        className="h-32"
        icon={Ratings[reaction].activeIcon}
        name="Rate our service"
        center
      />
      <BaseLabel>
        <TextMedium>{reaction}</TextMedium>
      </BaseLabel>
      <div className="flex h-24">
        {Object.keys(Ratings).map((key) => (
          <BaseIcon
            key={key}
            className="cursor-pointer"
            icon={
              reaction === key ? Ratings[key].activeIcon : Ratings[key].icon
            }
            name={key}
            onClick={() =>
              disabled ? {} : onEmojiChange(key, Ratings[key].value)
            }
          />
        ))}
      </div>
      <BaseButtonGroup>
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
          autoFocus
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default SubmitRating;
