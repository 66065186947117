import fetch from "./../helpers/fetch";

const baseUrl =  "/api";

//not using yet
const apiService = ( ) => ( next ) => ( action ) => {
    console.log("inside api service", action);
    if ( !action.meta || !action.meta.async) {
        if(typeof action === 'function') {
            return action;
        }
        const result = next( action );
        return result;
    }

    const { path, method = "GET", body } = action.meta;

    if ( !path ) {
        throw new Error( `'path' not specified for async action ${ action.type }` );
    }

    const url = `${ baseUrl }${ path }`;

    return fetch( url, method, body ).then(
        res => handleResponse( res, action, next ),
        err => handleErrors( err, action, next ),
    );
};

export default apiService;

function handleErrors( err, action, next ) {
    next( {
        type: `${ action.type }${'Failure'}`,
        payload: err,
        meta: action.meta,
    } );

    return Promise.reject( err );
}

function handleResponse( res, action, next ) {
    next( {
        type: `${ action.type }${'Success'}`,
        payload: res,
        meta: action.meta,
    } );

    return res;
}