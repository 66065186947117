
import Config from "./../Config";
import { getTwilioConfig } from "../../../helpers/utils"

const TwilioApis = {
    initWorkspace: (workspaceToken) => new window.Twilio.TaskRouter.Workspace(workspaceToken),
    getWorkspaceToken: async () => {
        let config = await getTwilioConfig();
        let region = 'sea';
    
        if (config) {
            sessionStorage.setItem("workflowId", config.workflowid);
            region = config.region;
        } else {
            sessionStorage.setItem("workflowId", Config.Twilio.WorkflowId);
        }
        return fetch(`${process.env.REACT_APP_SLS_BASE_ENDPOINT}/api/subsystem/v1/twilio/gettoken/?path=token&methodname=workspace&identifier=${region}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'x-api-key': process.env.REACT_APP_SLS_API_KEY },
        }).then((response) => response.json())
    },
    getRealtimeStatistics: (workspace) => {
        const taskQueueName = `${Config.Twilio.SkillId}_${Config.Twilio.language}`.toUpperCase();
        return new Promise((resolve, reject) => {
            workspace.taskqueues.statistics.fetch({ FriendlyName: taskQueueName }, function (error, statistics) {
            try {
                const workersOnChat = statistics[0].realtime.totalAvailableWorkers;
                console.log("TotalAvailableWorkers : ", workersOnChat);
                resolve(workersOnChat);
            } catch (e) {
                // reject(e)
                resolve(0)
                console.error('getRealtimeStatistics error', e)
            }
            });
        })
    },
    createTask: (workspace, skillId, language, visitorId, requestId, taskType) => {
        return new Promise((resolve, reject) => {
          const params = {
            WorkflowSid: sessionStorage.getItem("workflowId") || Config.Twilio.WorkflowId,
            TaskChannel: Config.Twilio.ChannelName,
            Timeout: Config.Twilio.Timeout,
            Attributes: JSON.stringify({
              skillId,
              selected_language: language ||  Config.Twilio.language,
              visitor_id: visitorId,
              request_id: requestId,
              task_type: taskType
            }),
          }
          console.log("twilio params::", JSON.parse(params.Attributes));
          workspace.tasks.create(params, (error, task) => {
            return error ? reject(error) : resolve(task.sid)
          })
        })
    },
    fetchTask: (workspace, taskSid) => {
        return new Promise((resolve, reject) => {
            workspace.tasks.fetch(taskSid, (error, task) => {
            return error ? reject(error) : resolve(task.assignmentStatus)
            })
        })
    }
}

export default TwilioApis;
