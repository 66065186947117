import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  messageList: [],
  showFlowWindow: false,
  showThankYouNote: false,
  showErrorNote: false,
  apiProgress: {
    text: "",
    percentage: 0,
  },
  flowProgress: {
    text: "",
    percentage: 0,
  },
  enquiryOption: "",
  caseType: "Screen Repair",
  repairOption: "",
};

const journeyMessagesReducer = createSlice({
  name: "journeyMessages",
  initialState,
  reducers: {
    //for naming convention, always use set as prefix to identify it as reducer
    resetStore() {
      return initialState;
    },
    setJourneyMessages(state, action) {
      state.messageList.push(action.payload);
    },
    toggleFlowWindow(state, action) {
      console.log(action);
      state.messageList = [];
      state.showThankYouNote = false;
      state.showErrorNote = false;
      state.showFlowWindow = action.payload;
      state.apiProgress = initialState.apiProgress;
      state.flowProgress = initialState.flowProgress;
      state.caseType = initialState.caseType;
    },
    setApiProgress(state, action) {
      state.apiProgress = action.payload;
    },
    setFlowProgress(state, action) {
      state.flowProgress = action.payload;
    },
    resetApiProgress(state) {
      state.apiProgress = initialState.apiProgress;
    },
    resetFlowProgress(state) {
      state.flowProgress = initialState.flowProgress;
    },
    setShowThankYouNote(state, action) {
      state.showThankYouNote = action.payload;
    },
    setEnquiryOption(state, action) {
      state.enquiryOption = action.payload;
    },
    setShowErrorNote(state, action) {
      state.showErrorNote = action.payload;
    },
    setCaseType(state, action) {
      state.caseType = action.payload;
    },
    setRepairOption(state, action) {
      state.repairOption = action.payload;
    },
  },
});

const { actions, reducer } = journeyMessagesReducer;
export const {
  setJourneyMessages,
  toggleFlowWindow,
  setApiProgress,
  resetApiProgress,
  setShowThankYouNote,
  setEnquiryOption,
  setFlowProgress,
  resetFlowProgress,
  setShowErrorNote,
  setCaseType,
  setRepairOption,
} = actions;
export default reducer;
