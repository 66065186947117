import React from "react";

const Timestamp = ({ time, children }) => {
  const timeVal = time || new Date().toISOString();

  return (
    <>
      <time className="app__message-time">
        {new Date(timeVal).toLocaleTimeString()}
      </time>
      {children}
    </>
  );
};

export default Timestamp;
