import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  visitor: {},
  conversationId: "",
  status: "",
  showChatInput: false,
  connect: false,
  chatReason: "",
};

const chatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    //for naming convention, always use set as prefix to identify it as reducer
    resetStore() {
      return {
        ...initialState,
        visitor: {},
      };
    },
    setVisitor(state, action) {
      state.visitor = action.payload;
    },
    setConversationId(state, action) {
      state.conversationId = action.payload;
    },
    setChatStatus(state, action) {
      state.status = action.payload;
    },
    setChatConnect(state, action) {
      state.connect = action.payload;
    },
    setChatReason(state, action) {
      state.chatReason = action.payload;
    },
    setChatInputVisibility(state, action) {
      state.showChatInput = action.payload;
    },
  },
});

const { actions, reducer } = chatReducer;
export const {
  resetStore,
  setVisitor,
  setConversationId,
  setChatStatus,
  setChatConnect,
  setChatReason,
  setChatInputVisibility,
} = actions;
export default reducer;
