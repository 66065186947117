import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextLight, TextMedium } from "../base/BaseText";
import ThankYouImage from "../images/image-thank-you.svg";
import BaseButton from "../base/BaseButton/BaseButton";

const ThankYouNote = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full bg-white flex flex-col overflow-hidden relative">
      <div className="w-full">
        <div className="mx-auto text-3xl mt-32 text-center relative z-10">
          <Trans i18nKey="ThankYouNote.Title">
            <TextLight>
              Thank you for using
              <br /> <TextMedium>Screen Repair Service!</TextMedium>
            </TextLight>
          </Trans>
            <BaseButton
              text={t("CloseButton")}
              onClick={onClose}
              autoFocus
              className="mx-auto w-11r"
            />
        </div>
        <div className="absolute bottom-0 h-full z-0 mx-auto w-full">
          <img
            className="object-cover h-full p-0 mx-auto"
            src={ThankYouImage}
            alt="Thank You"
          />
        </div>
      </div>
    </div>
  );
};

export default ThankYouNote;
