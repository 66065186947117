import React from "react";
import PropTypes from "prop-types";
import PrepareRequirements from "./PrepareRequirements";
import ConfirmRegisteredDevice from "./ConfirmRegisteredDevice";
import ChooseEnrolledDevice from "./ChooseEnrolledDevice";
import DeviceNotEligible from "./DeviceNotEligible";
import VerifyImei from "./VerifyImei";
import DeviceHold from "./DeviceHold";
import DisplayDeviceList from "./DisplayDeviceList";
import ConfirmIWTerms from "./ConfirmIWTerms";
import NoEnrolledDevice from "./NoEnrolledDevice";
import DeviceUnderReview from "./DeviceUnderReview";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "preparation" && <PrepareRequirements />}
      {showComponent === "confirmDevice" && <ConfirmRegisteredDevice />}
      {showComponent === "displayDevices" && <DisplayDeviceList />}
      {showComponent === "chooseEnrolledDevice" && <ChooseEnrolledDevice />}
      {showComponent === "deviceNotEligible" && <DeviceNotEligible />}
      {showComponent === "verifyImei" && <VerifyImei />}
      {showComponent === "deviceHold" && <DeviceHold />}
      {showComponent === "confirmIWTerms" && <ConfirmIWTerms />}
      {showComponent === "noEnrolledDevice" && <NoEnrolledDevice />}
      {showComponent === "deviceUnderReview" && <DeviceUnderReview />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
