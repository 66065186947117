import React from "react";
import PropTypes from "prop-types";
import { TextMedium } from "../BaseText";
import { isEmpty } from "../../helpers/formatUtils";

const BaseLoader = ({ icon, text }) => {
  return (
    <div className="mt-40%">
      <div className="flex items-center justify-center overflow-hidden relative">
        <div className="z-50 p-2">
          <img
            className="pb-6 w-32"
            src={icon}
            alt="Loading"
            style={{ paddingRight: "0.9rem", paddingBottom: "1.2rem" }}
          />
        </div>
        <div
          className="rounded-50% w-32 h-32 absolute opacity-50 bg-app-primary"
          style={{
            animation: "scaleIn 1.5s infinite cubic-bezier(.36, .11, .89, .32)",
          }}
        ></div>
      </div>
      {!isEmpty(text) && (
        <div className="text-center text-2xl mb-8">
          <TextMedium>{text}</TextMedium>
        </div>
      )}
    </div>
  );
};

BaseLoader.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default BaseLoader;
