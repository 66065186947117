import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import VerifyPhoneIcon from "../../../images/icon-verify-phone.svg";
import { ReactComponent as HuaweiPhoneIcon } from "../../../images/image-phone-huawei.svg";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { isRegisteredDeviceConfirmed } from "../state/operators/deviceConfirmationOperators";
import { isEmpty, toPascalCase } from "../../../helpers/formatUtils";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const ConfirmRegisteredDevice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetData = useSelector(
    (state) => state.validation.verification.enrolledAsset
  );

  const feeData = useSelector((state) => state.claim.payment.serviceFee);
  const fee = isEmpty(feeData) ? "99" : feeData.TotalAmountWithDiscount || feeData.TotalAmount;
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest;
  
  const registeredPhone = `${toPascalCase(assetData.Make.Name)} ${assetData.AssetCatalog.AssetCatalogName}`;
  const [disabled, setDisabled] = useState(false);
  const [isCorrectDevice, setIsCorrectDevice] = useState("");

  const handleSubmit = (isDeviceRegistered) => {
    setIsCorrectDevice(isDeviceRegistered ? "Yes" : "No");
    setDisabled(true);
    dispatch(isRegisteredDeviceConfirmed(isDeviceRegistered, assetData));
  };

  return (
    <BaseCard>
      <BaseIcon icon={VerifyPhoneIcon} name="Verify Phone" center />
      <BaseLabel>
        <Trans i18nKey="ConfirmRegisteredDevice.Title">
          <TextLight>
            Please confirm whether
            <br />
            the following is your Registered device?
          </TextLight>
        </Trans>
      </BaseLabel>
      <div className="flex mx-auto mb-4">
        <div className="mr-4">
          <HuaweiPhoneIcon />
        </div>
        <div className="py-2 my-auto w-20r">
          <div className="text-lg">
            <TextLight>{registeredPhone}</TextLight>
          </div>
          <div className="text-xl py-2">
            <Trans i18nKey="ConfirmRegisteredDevice.RegisteredDevice">
              <TextMedium>REGISTERED DEVICE</TextMedium>
            </Trans>
          </div>
        </div>
      </div>
      {!isInWarranty && (
        <div className="text-center m-4 text-default">
          <TextMedium>
            Screen Repair Fee of ${fee} (inclusive of GST)
          </TextMedium>
        </div>
      )}
      <BaseButtonGroup>
        <BaseButton
          text={t('NoButton')}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          clicked={isCorrectDevice === "No"}
          className="px-16"
        />
        <BaseButton
          text={t('YesButton')}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          clicked={isCorrectDevice === "Yes"}
          className="px-16"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRegisteredDevice;
