
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import fetch from "./../../../helpers/fetch";
import {
    CreateVisitorMutation,
    UpdateVisitorMutation,
    CreateChatRequestMutation,
    UpdateChatRequestMutation,
    SubscribeToNewConversation,
    SubscribeToNewMessage,
    CreateMessageMutation,
    SubscribeToEndChat,
    CreateChatAudit,
    SubscribeToAgentTyping,
    UpdateVisitorTyping
  } from './../Queries';

const ChatApis = {
    getCognitoId: () => Auth.currentCredentials(),
    createVisitor: (visitor) => {
      return API.graphql(graphqlOperation(CreateVisitorMutation, { input: visitor }))
    },
    updateVisitorTyping: (visitorTyping) => {
      return API.graphql(graphqlOperation(UpdateVisitorTyping, { input: visitorTyping }))
    },
    updateVisitor: (visitor) => {
      return API.graphql(graphqlOperation(UpdateVisitorMutation, { input: visitor }))
    },
    createChatAudit: (chatAudit) => {
      return API.graphql(graphqlOperation(CreateChatAudit, { input: chatAudit }))
    },
    createChatRequest: (chatRequest) => {
      return API.graphql(graphqlOperation(CreateChatRequestMutation, { input: chatRequest }))
    },
    updateChatRequest: (chatRequest) => {
      return API.graphql(graphqlOperation(UpdateChatRequestMutation, { input: chatRequest }))
    },
    createChatMessage: (chatMessage) => {
      return API.graphql(graphqlOperation(CreateMessageMutation, { input: chatMessage }))
    },
    subscribeToNewConversation: (requestId) => {
      return API.graphql(graphqlOperation(SubscribeToNewConversation, { requestId }))
    },
    subscribeToNewMessages: (conversationId) => {
      return API.graphql(graphqlOperation(SubscribeToNewMessage, { conversationId }))
    },
    subscribeToEndChat: (requestId) => {
      return API.graphql(graphqlOperation(SubscribeToEndChat, { requestId }))
    },
    subscribeToAgentTyping: (conversationId) => {
      return API.graphql(graphqlOperation(SubscribeToAgentTyping, { conversationId }))
    },
}

export default ChatApis;