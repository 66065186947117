import React from "react";
import PropTypes from "prop-types";
import ConfirmContactDetails from "./ConfirmContactDetails";
import ChangeContactDetails from "./ChangeContactDetails";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "ConfirmMobileNumber" && <ConfirmContactDetails code="ConfirmMobileNumber" />}
      {showComponent === "ConfirmEmail" && <ConfirmContactDetails code="ConfirmEmail" />}
      {showComponent === "ChangeMobileNumber" && <ChangeContactDetails code="ChangeMobileNumber" />}
      {showComponent === "ChangeEmail" && <ChangeContactDetails code="ChangeEmail" />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
