import React from "react";
import CameraIcon from "../../../images/icon-camera.svg";
import PowerIcon from "../../../images/icon-power-button.svg";
import LiquidExposeIcon from "../../../images/icon-liquid-expose.svg";
import PhysicalDamageIcon from "../../../images/icon-screen-damage.svg";
import { TextLight, TextMedium } from "../../../base/BaseText";

const CameraWorking = {
  icon: CameraIcon,
  title: (
    <TextLight>
      My device's camera is in good working condition and it's still able to
      take photos.
    </TextLight>
  ),
};

const PowerOn = {
  icon: PowerIcon,
  title: <TextLight>My device is able to power on.</TextLight>,
};

const LiquidExposure = {
  icon: LiquidExposeIcon,
  title: (
    <TextLight>
      My device has <TextMedium>not</TextMedium> been exposed to any liquid
    </TextLight>
  ),
};

const PhysicalDamage = {
  icon: PhysicalDamageIcon,
  title: (
    <TextLight>
      My device does <TextMedium>NOT</TextMedium> have any other physical damage
      other than just a damaged screen. (for example device being bent)
    </TextLight>
  ),
};

export default {
  CameraWorking,
  PowerOn,
  LiquidExposure,
  PhysicalDamage,
};
