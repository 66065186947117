import React, { useState } from "react";
import { TextBold, TextLight, TextMedium } from "../../../base/BaseText";
// import WalkInImage from "../../../images/img_walkin.svg";
// import PURImage from "../../../images/img_pick_return.svg";
import BaseCard from "../../../base/BaseCard/BaseCard";
// import { Translate, useTranslate } from "../../../helpers/languageUtils";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { confirmationOfRepairOption } from "../state/operators/RepairOptionsOperator";

const SelectRepairOption = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [isWalkIn, setWalkIn] = useState(false);
  const [isPUR, setPUR] = useState(false);

  const wiLabel = t("SelectRepairOption.OptionWalkIn")
  .replace(/[<0><0/><1><1/><3><3/>]/g, "")
  .trim();

  const purLabel = t("SelectRepairOption.OptionPUR")
  .replace(/[<0><0/><1><1/><3><3/>]/g, "")
  .trim();

  function selectOption(option) {
    setDisabled(true);
    let repairOption, textToPrint;
    if (option === "WalkIn") {
      setWalkIn(true);
      repairOption = "WALKIN";
      textToPrint = wiLabel
    } else {
      setPUR(true);
      repairOption = "PUR";
      textToPrint = purLabel
    }

    dispatch(confirmationOfRepairOption(repairOption, textToPrint));
  }

  return (
    <div>
      <div className="text-center mx-auto">
        <div className="my-0 mt-4 pl-6 text-2xl">
          <Trans i18nKey="SelectRepairOption.title">
            <TextBold>Please select your preferred repair option:</TextBold>
          </Trans>
        </div>
        {/* <Trans i18nKey="SelectRepairOption.subTitle">
          <TextLight className="text-xl">
            You will not be able to change the repair option for this request
            after this step.
          </TextLight>
        </Trans> */}
      </div>
      <div className="px-10 flex justify-center" disabled={disabled}>
        <BaseCard
          className={`w-50% flex flex-col mr-3 p-0 mt-4 ${
            isWalkIn ? "bg-accordion-bgx border-app-primary border-2" : "border"
          }`}
          style={{ marginLeft: "auto"}}
        >
          <div
            className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={() => !disabled && selectOption("WalkIn")}
          >
            {/*<div className="h-48">
              <img className="h-full p-4 mx-auto" src={WalkInImage} alt="Walk-In" />
            </div>*/}
            <div className="text-center text-2xl mt-2 px-4 pb-4">
              <Trans i18nKey="SelectRepairOption.OptionWalkIn">
                <TextMedium>
                  Walk-In
                  <br /> <TextMedium>to service Center</TextMedium>
                </TextMedium>
              </Trans>
            </div>
          </div>
        </BaseCard>

        <BaseCard
          className={`w-50% flex flex-col mr-auto p-0 mt-4 ${
            isPUR ? "bg-accordion-bgx border-app-primary border-2" : "border"
          }`}
          style={{ marginLeft: "0.75rem"}}
        >
          <div
            className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={() => !disabled && selectOption("PUR")}
          >
            {/*<div className="h-48">
              <img className="h-full p-4 mx-auto" src={PURImage} alt="PUR" />
            </div>*/}
            <div className="text-center text-2xl mt-2 px-4 pb-4">
              <Trans i18nKey="SelectRepairOption.OptionPUR">
                <TextMedium>
                  Pick-up and Return
                  <br />
                  <TextMedium> to my address</TextMedium>
                </TextMedium>
              </Trans>
            </div>
          </div>
        </BaseCard>
      </div>
    </div>
  );
};

export default SelectRepairOption;
