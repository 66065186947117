import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { sleep } from "../../../../helpers/addJourneyMessages";
import { endFlow } from "../../../../actions/flowWindow";

export const importantNoteLoaded = () => async (dispatch, getStore) => {
  const { enquiryOption } = getStore().journeyMessages;
  switch (enquiryOption) {
    case EnumEnquiryModules.ChangeSchedule:
      dispatch(endFlow());
      break;
    case EnumEnquiryModules.InWarrantyRequest:
      await sleep(5000);
      dispatch(endFlow());
      break;
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.ResumeRequest:
    default:
      break;
  }
};
