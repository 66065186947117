import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import RequirementsIcon from "../../../images/icon-requirements.svg";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import { TextLight, TextMedium } from "../../../base/BaseText";
import { proceedForDeviceConfirmation } from "../state/operators/deviceConfirmationOperators";

const PrepareRequirements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const submitRequirementPrepared = () => {
    dispatch(proceedForDeviceConfirmation("proceed"));
    setDisabled(true);
  };

  return (
    <div className="app-PrepareRequirements">
      <BaseCard>
        <BaseIcon icon={RequirementsIcon} name="Prepare Requirements" center />
        <BaseLabel>
          <TextMedium>{t("PrepareRequirements.Title")}</TextMedium>
        </BaseLabel>
        <BaseLabel>
          <Trans i18nKey="PrepareRequirements.Labels.1">
            <TextLight>
              You selected <TextMedium> New Screen Repair Request</TextMedium>
              .<br /> It will take 8 mins to finish this procedure.
            </TextLight>
          </Trans>
        </BaseLabel>
        <BaseLabel>
          <Trans i18nKey="PrepareRequirements.Labels.2">
            <TextLight>
              Prepare the following requirements
              <br /> before we proceed to the next step.
            </TextLight>
          </Trans>
        </BaseLabel>
        <BaseInformationBox>
          <ul className="list-disc list-inside">
            <li>
              <Trans i18nKey="PrepareRequirements.Information.1">
                Have your device <TextMedium> IMEI number</TextMedium>
              </Trans>
            </li>
            <li>
              <Trans i18nKey="PrepareRequirements.Information.2">
                Please prepare your{" "}
                <TextMedium> credit card or debit card details</TextMedium>.
              </Trans>
            </li>
          </ul>
        </BaseInformationBox>
        <BaseButtonGroup>
          <BaseButton
            text={t("PrepareRequirements.ProceedBtn")}
            onClick={submitRequirementPrepared}
            disabled={disabled}
            clicked={disabled}
            autoFocus
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
};

export default PrepareRequirements;
