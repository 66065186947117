import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import { handleDeviceHoldClose } from "../state/operators/deviceConfirmationOperators";

const DeviceHold = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    let text = t("CloseButton");
    setDisabled(true);
    dispatch(handleDeviceHoldClose(text));
  };

  return (
    <BaseCard>
      <BaseLabel className="text-1.3">
        <Trans i18nKey="DeviceHold.Title">
          <TextLight>
            Please note that your request has been submitted to our internal
            review process.
            <br />
            <br />
            So, You will be notified by SMS.
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("CloseButton")}
          onClick={handleClose}
          disabled={disabled}
          clicked={disabled}
          autoFocus
          focused
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default DeviceHold;
