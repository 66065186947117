import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sleep } from "../../helpers/addJourneyMessages";
import { sanitize, isEmpty } from "../../helpers/formatUtils";

const BaseSeparatedInput = ({
  name,
  value,
  onChange,
  onEnterPress,
  placeholder,
  length,
  disabled,
  className,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  if (!value) value = new Array(length);

  const handleChangeValue = (e, index) => {
    const oldInputValue = [...value];
    const inputValue = e.target.value.trim();
    if (isEmpty(inputValue) || /[a-zA-Z0-9]/.test(inputValue)) {
      const sanitizedValue = sanitize(inputValue);
      oldInputValue[index] = sanitizedValue;
      onChange(oldInputValue.join(""));
    }
  };

  const handleKeyPress = async (e, i) => {
    if (e.keyCode === 13 && onEnterPress) onEnterPress();
    // Move to previous input for backspace key press
    else if (e.keyCode === 8 && i !== 0) {
      await sleep(10);
      document
        .getElementById(
          `app-BaseSeparatedInput__input_${name}_${i - 1}`
        )
        ?.focus();
    }
    // Move to next input for alphanumeric key press
    else if (
      i !== length - 1 &&
      /[a-zA-Z0-9]/.test(String.fromCharCode(e.keyCode))
    )
      document
        .getElementById(
          `app-BaseSeparatedInput__input_${name}_${i + 1}`
        )
        ?.focus();
  };

  const InputArray = () => {
    const inputs = [];
    for (let i = 0; i < length; i++) {
      inputs.push(
        <input
          key={i}
          name={name}
          id={`app-BaseSeparatedInput__input_${name}_${i}`}
          className={classNames(
            "app-BaseSeparatedInput__input",
            { "opacity-50 bg-srgray-border border-srgray-border": disabled },
            ...extraClasses
          )}
          value={value[i] || ""}
          onChange={(e) => handleChangeValue(e, i)}
          onKeyDown={(e) => handleKeyPress(e, i)}
          placeholder={placeholder && placeholder.split()[i]}
          maxLength={1}
          disabled={disabled}
          {...restProps}
          autoFocus={i === 0}
        />
      );
    }
    return inputs;
  };

  return <div className="app-BaseSeparatedInput">{InputArray()}</div>;
};

BaseSeparatedInput.defaultProps = {
  length: 1,
  disabled: false,
};

BaseSeparatedInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func,
  length: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default BaseSeparatedInput;
