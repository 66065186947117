import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function BaseIcon({ icon, center, name, className, ...restProps }) {
  const extraClasses = className ? className.split(" ") : [];

  return (
    <div
      className={classNames(
        "app-BaseIcon",
        {
          "app-BaseIcon-center": center,
        },
        ...extraClasses
      )}
      {...restProps}
    >
      <img className="inline-block h-full" src={icon} alt={name} />
    </div>
  );
}

BaseIcon.default = {
  center: false,
};

BaseIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  name: PropTypes.string,
  center: PropTypes.bool,
  className: PropTypes.string,
};

export default BaseIcon;
