import { callGetSurveyApi, callSubmitSurveyApi } from "../reducers/npsReducer";

export const getSurvey = (
  clientId,
  requestId,
  lang,
  triggerPoint,
  surveyType
) => ({
  type: callGetSurveyApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/subsystem/v1/survey",
    method: "POST",
    body: {
      GetSurveyQuestions: {
        ClientId: clientId,
        TriggerPoint: triggerPoint,
        SurveyType: surveyType,
        ServiceRequestId: requestId,
        Language: lang,
      },
    },
  },
});

export const submitSurvey = (
  visitorId,
  surveyId,
  clientId,
  engagementId,
  mdn,
  interactionId,
  data
) => ({
  type: callSubmitSurveyApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/subsystem/v1/survey/submit",
    method: "POST",
    body: {
      SubmitSurveyAnswers: {
        VisitorId: visitorId,
        SurveyId: surveyId,
        ClientId: clientId,
        engagementId: engagementId,
        mobileNumber: mdn,
        InteractionLineId: interactionId,
        Results: data,
      },
    },
  },
});
