import React, { useEffect, useState } from "react";
import useChat from "./useChat";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch, useSelector } from "react-redux";
import { addJourneyMessages } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";
import { isEmpty } from "../../../helpers/formatUtils";

import { setConversationId, setChatStatus } from "../state/reducers";
import { CHAT_STATUS } from "./constants";
import {
  updateChatConnect,
  udpateChatInputVisibility,
  resumeRepairFlow,
} from "../state/operators";
import Config from "../Config";

export default function Chat(props) {
  const dispatch = useDispatch();
  const mdn = useSelector((state) => state.validation.inputData.mdn);
  const { chatReason, connect: connection } = useSelector(
    (state) => state.chat
  );
  const [msg, setMsg] = useState("");
  const [connectAttempt, setConnectAttempt] = useState(0);
  const { closeChat } = props;
  // let reason = "";
  const [reason, setReason] = useState(chatReason || "");

  const handleError = (errorCode) => (errorMsg) => {
    console.log(errorCode, "::", errorMsg);
  };

  const handleIncomingMessages = (message) => {
    if (!isEmpty(reason)) {
      dispatch(
        addJourneyMessages([createMessage("TEXT", "agent", message.content)])
      );
    }
  };

  const resumeFlow = (code) => {
    dispatch(resumeRepairFlow(code));
  };

  const visitorDetails = {
    ...props.visitorDetails,
    mdn,
    skillId: Config.Twilio.SkillId,
    chatReason,
  };

  const { startChat, disconnectChat, ChatStatus, ConversationId } = useChat({
    visitorDetails,
    handleError,
    handleIncomingMessages,
    resumeFlow,
  });

  const initiateChat = async () => {
    const response = await startChat(connectAttempt);
    if (response === CHAT_STATUS.ABANDONED && connectAttempt < 3) {
      setConnectAttempt(connectAttempt + 1);
    }
  };

  const takeActionOnChatStatus = (status) => {
    console.log("chat status::", status);
    dispatch(setChatStatus(status));
    switch (status) {
      case CHAT_STATUS.CONNECTED:
        dispatch(
          addJourneyMessages([
            createMessage("CHAT", "user", { showComponent: "AgentConnected" }),
          ])
        );
        dispatch(updateChatConnect(true));
        dispatch(setConversationId(ConversationId));
        break;
      case CHAT_STATUS.AGENT_DISCONNECT:
        dispatch(setConversationId(""));
        dispatch(
          addJourneyMessages([
            createMessage("CHAT", "user", {
              showComponent: "AgentDisconnected",
            }),
          ])
        );
        dispatch(udpateChatInputVisibility(false));
        break;
      case CHAT_STATUS.ABANDONED:
        dispatch(setConversationId(""));
        dispatch(
          addJourneyMessages([
            createMessage("CHAT", "user", {
              showComponent: "NoAgentsAvailable",
            }),
          ])
        );
        dispatch(udpateChatInputVisibility(false));
        break;
      case CHAT_STATUS.AGENT_BUSY:
        dispatch(setConversationId(""));
        dispatch(
          addJourneyMessages([
            createMessage("CHAT", "user", {
              showComponent: "AgentsBusy",
            }),
          ])
        );
        dispatch(udpateChatInputVisibility(false));
        break;
      case CHAT_STATUS.CHAT_RETRY:
        dispatch(setConversationId(""));
        dispatch(
          addJourneyMessages([
            createMessage("CHAT", "user", {
              showComponent: "ChatRetry",
            }),
          ])
        );
        dispatch(udpateChatInputVisibility(false));
        break;
      case CHAT_STATUS.USER_DISCONNECT:
        dispatch(setConversationId(""));
        dispatch(udpateChatInputVisibility(false));
        closeChat();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    takeActionOnChatStatus(ChatStatus);
    //Chat Initiated
    //Chat Connected
    //Chat Disconnected
    //Chat Abandoned
  }, [ChatStatus]);

  useEffect(() => {
    if (!connection && ChatStatus === CHAT_STATUS.CONNECTED) {
      disconnectChat();
    }
  }, [connection]);

  useEffect(() => {
    if (connectAttempt <= 3) {
      console.log("inside chat");
      initiateChat();
    }
  }, [connectAttempt]);
  
  useEffect(() => {
    // reason = chatReason;
    setReason(chatReason)
  }, [chatReason]);

  useEffect(() => {
    return () => {
      // reason = "";
      setReason("")
    };
  }, []);

  const handleSendMessage = () => {
    if (isEmpty(msg)) return;
    dispatch(addJourneyMessages([createMessage("TEXT", "user", msg)]));
    setMsg("");
  };

  return (
    <>
      {ChatStatus === CHAT_STATUS.CONNECTED && (
        <div className="px-4 flex flex-row">
          <BaseInput
            className="pr-4 mr-4"
            placeholder="Type something here..."
            value={msg}
            onChange={setMsg}
            onEnterPress={handleSendMessage}
            full
            showLabel={false}
          />
          <BaseButton
            className="px-2"
            text="Send"
            onClick={handleSendMessage}
            focused
          />
        </div>
      )}
    </>
  );
}
