import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "./../images/icon-close-white.svg";
import { TextLight } from "../base/BaseText";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";
import { useSelector } from "react-redux";
import { COMPONENTS } from "../helpers/constants";

const HeaderProgress = ({ text, percentage, initial = 0 }) => {
  return (
    <>
      {percentage > 0 && percentage < 100 ? (
        <div className="flex flex-row w-full">
          <div className="text-5xl mr-4">
            {percentage}
            <span className="text-2xl pl-1 text-center">%</span>
          </div>
          <div className="pt-2 w-full pr-4">
            <div className="text-xl">
              <TextLight>{text}</TextLight>
            </div>
            <BaseProgressBar
              className="h-2 mt-2 mb-0 w-full"
              percentage={percentage}
              initial={initial}
              type={COMPONENTS.FLOW_PROGRESS}
              uniqueId={COMPONENTS.FLOW_PROGRESS}
            />
          </div>
        </div>
      ) : percentage === 100 ? (
        "Screen Repair request submitted"
      ) : (
        <div className="p-2"><TextLight>Screen Repair Portal</TextLight></div>
      )}
    </>
  );
};

const FlowHeader = ({ onClose }) => {
  const flowProgressData = useSelector(
    (state) => state.journeyMessages.flowProgress
  );

  return (
    <div className="app__flow-header">
      <div className="app__flow-content">
        <HeaderProgress {...flowProgressData} />
      </div>
      <div className="app__flow-exit-button" onClick={onClose}>
        <img src={CloseIcon} alt="Close" />
      </div>
    </div>
  );
};

FlowHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FlowHeader;
