import React, { useState } from "react";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import CancelRequestIcon from "../../../images/icon-request-cancel.svg";
import { useTranslation, Trans } from "react-i18next";
import { TextLight } from "../../../base/BaseText";
import { useDispatch } from "react-redux";
import { confirmToCancelRequest, discardCancelRequest } from "../state/operators/cancelRequestOperator";

const ConfirmRequestCancel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");

  const handleSubmitCancelRequest = (status) => {
    setDisabled(true);
    setStatus(status);
    if (status === "Yes") {
      dispatch(confirmToCancelRequest(status));
    } else {
      dispatch(discardCancelRequest(status));
    }
  };

  return (
    <BaseCard>
      <BaseIcon icon={CancelRequestIcon} name="Cancel Request Icon" center />
      <BaseLabel>
        <Trans i18nKey="ConfirmRequestCancel.Title">
          <TextLight>
            Are you sure you want to cancel your Screen Repair Request?
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmitCancelRequest("No")}
          disabled={disabled}
          clicked={status === "No"}
        />
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmitCancelRequest("Yes")}
          disabled={disabled}
          clicked={status === "Yes"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRequestCancel;
