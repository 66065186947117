import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import CONSTANTS, {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
} from "../../../../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { updateContactDetails } from "../actions/contactsApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { getPciToken } from "../actions/paymentApi";
import { isEmpty } from "../../../../helpers/formatUtils";
import { setContactDetails } from "../../../Validation/state/reducers";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import { submitCODPayment } from "./paymentOperator";
import { confirmationOfDetails } from "./scheduleOperator";
import { INCIDENT_SCREEN_REPAIR_WARRANTY } from "../../../../components/EnquiryOptions/Constants";

export const submitConfirmationOfContactDetails = (status, code) => async (
  dispatch,
  getStore
) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));

  const isMobile = code === "ConfirmMobileNumber";
  const isCorrect = status === "Yes";
  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const fee = getStore().claim.payment.serviceFee.TotalAmount;
  const discountedfee = getStore().claim.payment.serviceFee.TotalAmountWithDiscount;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const caseType = getStore().journeyMessages.caseType;
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || caseType?.toUpperCase() === INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase();  

  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "";

  if (isMobile) {
    msg = API_PROGRESS.UPDATE_CONTACT_NUMBER;
    msg_success = API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS;
  } else {
    msg = API_PROGRESS.UPDATE_CONTACT_EMAIL;
    msg_success = API_PROGRESS.UPDATE_CONTACT_EMAIL_SUCCESS;
  }

  if (isCorrect) {
    await dispatch(updateApiProgress(msg, 40, COMPONENTS.UPDATE_CONTACT));
    // const contactResponse = await dispatch(
    //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
    // ).catch((err) => dispatch(serviceUnavailable()));
    // if (isEmpty(contactResponse)) return;
    let contactResponse = null;
    let retrycount = 0;
    while (retrycount < 2) {
      contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
      .catch((err) => { return null }
      );

      if (contactResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(contactResponse)) {
      dispatch(serviceUnavailable())
      return;
    }

    await dispatch(
      updateApiProgress(msg_success, 100, COMPONENTS.UPDATE_CONTACT, 40)
    );
    
    /**
     * Calling serviceOrder to shippingOrder API after contactPointAPI success
     */
    //  !isMobile && await dispatch(confirmationOfDetails())
    if (!isMobile) {
      let res = await dispatch(confirmationOfDetails());
      if (!res) return;
    }

    await sleep(1000);
    await dispatch(clearApiProgress());
  }

  const isEmailAvailable = !isEmpty(
    getStore().validation.verification.EmailAddress
  ) && 
  getStore().validation.verification.EmailAddress !== "EMAIL_NOT_AVAILABLE";

  if (isMobile) {
    showComponent = isCorrect
      ? isEmailAvailable
        ? "ConfirmEmail"
        : "ChangeEmail"
      : "ChangeMobileNumber";
  } else {
    showComponent = isCorrect ? "SubmitCardDetails" : "ChangeEmail";
    module = isCorrect ? "PAYMENT" : module;
    if (isCorrect && isInWarranty) {
      await dispatch(submitCODPayment());
      return;
    } else if (isCorrect) {
      dispatch(getPciToken(CacheId, (discountedfee || fee))).catch((err) =>
        dispatch(serviceUnavailable())
      );

      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.PaymentProcessing",
          }),
        ])
      );

      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.PAYMENT.title,
          PROGRESS_DATA.PAYMENT.count,
          initial
        )
      );
    }
  }
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const submitChangesInContactDetails = (contactData, isMobile) => async (
  dispatch,
  getStore
) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", contactData)]));

  const { CacheId } = getStore().session.sessionData.Configurations;
  const { ServiceRequestId } = getStore().serviceRequest.serviceRequestDetails;
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  const caseType = getStore().journeyMessages.caseType;
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || caseType?.toUpperCase() === INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase();  
  let {
    phoneNumber: PhoneNumber,
    emailAddress: EmailAddress,
  } = getStore().validation.inputData;
  let showComponent = "";
  let module = "CONTACT_CONFIRMATION";
  let msg = "",
    msg_success = "",
    component = "";
  if (isMobile) {
    const isEmailAvailable = !isEmpty(
      getStore().validation.verification.EmailAddress
    ) && 
    getStore().validation.verification.EmailAddress !== "EMAIL_NOT_AVAILABLE";
    showComponent = isEmailAvailable ? "ConfirmEmail" : "ChangeEmail";
    PhoneNumber = contactData;
    msg = API_PROGRESS.UPDATE_CONTACT_NUMBER;
    msg_success = API_PROGRESS.UPDATE_CONTACT_NUMBER_SUCCESS;
    component = COMPONENTS.UPDATE_CONTACT;
  } else {
    showComponent = "SubmitCardDetails";
    module = "PAYMENT";
    EmailAddress = contactData;
    msg = API_PROGRESS.UPDATE_CONTACT_EMAIL;
    msg_success = API_PROGRESS.UPDATE_CONTACT_EMAIL_SUCCESS;
    component = COMPONENTS.UPDATE_CONTACT;
  }
  await dispatch(updateApiProgress(msg, 40, component));
  // const contactResponse = await dispatch(
  //   updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress)
  // ).catch((err) => dispatch(serviceUnavailable()));
  // // return if response is empty
  // if (isEmpty(contactResponse)) return;
  let contactResponse = null;
  let retrycount = 0;
  while (retrycount < 2) {
    contactResponse = await dispatch(updateContactDetails(CacheId, ServiceRequestId, PhoneNumber, EmailAddress))
    .catch((err) => { return null }
    );

    if (contactResponse === null) {
      retrycount++;
    } else {
      retrycount = 2;
    }
  }
  // not allowing to go ahead if response is empty
  if (isEmpty(contactResponse)) {
    dispatch(serviceUnavailable())
    return;
  }

  await dispatch(updateContactDetailsData(PhoneNumber, EmailAddress));
  dispatch(
    updateVisitor({
      lastActivity: isMobile
        ? ACTIVITY.UPDATE_CONTACT_NUMBER
        : ACTIVITY.UPDATE_CONTACT_EMAIL,
    })
  );
  const initial = getStore().journeyMessages.flowProgress.percentage;
  /**
   * Calling serviceOrder to shippingOrder API after contactPointAPI success
   */
  // !isMobile && await dispatch(confirmationOfDetails())
  if (!isMobile) {
    let res = await dispatch(confirmationOfDetails());
    if (!res) return;

    if (isInWarranty) {
      await dispatch(updateApiProgress(msg_success, 100, component, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());
      dispatch(submitCODPayment());
      return;
    }
    if (module === "PAYMENT") {
      const { CacheId } = getStore().session.sessionData.Configurations;
      const fee = getStore().claim.payment.serviceFee.TotalAmount;
      const discountedfee = getStore().claim.payment.serviceFee.TotalAmountWithDiscount;
      dispatch(getPciToken(CacheId, (discountedfee || fee))).catch((err) =>
        dispatch(serviceUnavailable())
      );
      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.PaymentProcessing",
          }),
        ])
      );
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.PAYMENT.title,
          PROGRESS_DATA.PAYMENT.count,
          initial
        )
      );
    }
  }

  await dispatch(updateApiProgress(msg_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  dispatch(
    addJourneyMessages([createMessage(module, "system", { showComponent })])
  );
};

export const updateContactDetailsData = (phone = "", email = "") => async (
  dispatch,
  getStore
) => {
  let phoneNumber = phone,
    emailAddress = email;
  const { PhoneNumber, EmailAddress } = getStore().validation.agreement;
  if (isEmpty(phone)) {
    phoneNumber = PhoneNumber;
  }
  if (isEmpty(email)) {
    emailAddress = EmailAddress;
  }
  dispatch({
    type: setContactDetails.toString(),
    payload: {
      phoneNumber,
      emailAddress,
    },
  });
};

export const cancelledContactUpdate = () => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", "Cancel")]));
  dispatch(confirmToCancelRequest("", "Cancelled Contact Update", true));
};
