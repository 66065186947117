import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderMessageOfType from "../../components/RenderMessageOfType";

export default function JourneyMsgs() {
  const dispatch = useDispatch();
  const journeyMessages = useSelector(
    (state) => state.journeyMessages.messageList
  );

  let scrollAvailableHeight = 0;
  const flowBody = document.getElementById("app-Flow__body");
  const scrollToTop = setInterval(() => {
    if (flowBody && scrollAvailableHeight !== flowBody.scrollHeight) {
      scrollAvailableHeight = flowBody.scrollHeight;
      flowBody.scrollTo(0, scrollAvailableHeight);
    }
  }, 1000);

  useEffect(() => {
    console.log("initiating process flow...");

    //call initProcessFlow Action creator which will actually add first three messages
    /* dispatch(
      addJourneyMessages([
        createMessage("WELCOME_TO_SERVICE", "system"),
        createMessage("TEXT","system","Welcome to screen repair service"),
        createMessage("TEXT","user","Thank you"),        
      ])
    ); */
    return function clearAll() {
      clearInterval(scrollToTop);
    };
  }, [dispatch]);

  return (
    <div className="app__message-list">
      {journeyMessages &&
        journeyMessages.map((message) => (
          <div key={message.messageId} className="app__message-block">
            <RenderMessageOfType message={message} />
          </div>
        ))}
    </div>
  );
}
