import React, { useState } from "react";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { saveEnquiryDetails } from "../state/operators/generalEnquiryOperator";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import FormIcon from "../../../images/icon-requirements.svg";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

const ProvideEnquiryDetails = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [details, setDetails] = useState("");
  const { t } = useTranslation();

  const handleSubmit = () => {
    dispatch(saveEnquiryDetails(details));
    setDisabled(true);
  };

  return (
    <BaseCard>
      <BaseIcon icon={FormIcon} name="Enquiry Details" center />
      <BaseLabel>{t("GeneralEnquiry.ProvideEnquiryDetails.Title")}</BaseLabel>
      <BaseTextarea
        className="w-25"
        name="EnquiryDetails"
        placeholder={t("GeneralEnquiry.ProvideEnquiryDetails.TypeDetails")}
        value={details}
        onChange={setDetails}
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ProvideEnquiryDetails;
