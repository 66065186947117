import React from "react";
import { TextMedium } from "../../../base/BaseText";

export default [
  {
    title: "Under the Screen Repair service:",
    content: (
      <>
        <TextMedium>1.</TextMedium> You must be an active subscriber of M1
        Limited’s (“
        <TextMedium>M1</TextMedium>”) FoneCare+ device protection programme (“
        <TextMedium>FoneCare+</TextMedium>”) and the international mobile
        equipment identity number (“<TextMedium>IMEI</TextMedium>”) of your
        mobile device must be registered and active under M1 FoneCare.
        <br />
        <br />
        <TextMedium>2.</TextMedium> Subject to these terms and conditions, NEW
        Asurion Singapore Pte. Ltd. (“<TextMedium>Asurion</TextMedium>”), as
        M1’s appointed service provider, will carry out a repair or replacement
        of the screen of your mobile device (“
        <TextMedium>Screen Repair</TextMedium>”) and, if any other repairs are
        required, the Screen Repair will not be carried out on your mobile
        device.
        <br />
        <br />
        <TextMedium>3.</TextMedium> The Screen Repair fee is as follows: 
        <br/>

        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">(a)</td>
                <td>
                S$99 - Device RRP up to S$2,300
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(b)</td>
                <td>
                S$299 - Device RRP more than S$2,300
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>4.</TextMedium> Your mobile device will be collected and
        returned to you at the same indicated location and at the indicated
        time(s). You accept and acknowledge that the timing of the return of
        your mobile device is subject to the availability of replacement parts
        and availability of our delivery personnel. Our delivery personnel will
        verify your identity at the time of collection and return of your mobile
        device and will seek your written acknowledgement and acceptance at both
        times. You may be charged an additional fee if you are not present when
        we attempt to collect your mobile device from you or at the time we
        attempt to return your mobile device to you at the indicated times.
        Access to Screen Repair is available in Singapore only.
        <br />
        <br />
        <TextMedium>5.</TextMedium> Upon receipt of your mobile device, the
        suitability of your mobile device will be assessed for Screen Repair. We
        reserve the right not to proceed with the Screen Repair for any reason,
        whether relating to make, model and/or condition of the device or
        otherwise. In such event, your mobile device will not be accepted for
        repair and/or the unrepaired mobile device will be returned to you with
        the Repair Fee refunded to you in full if you have paid for it.
        <br />
        <br />
        <TextMedium>6.</TextMedium> Your mobile device (including the operating
        system and hardware) must not be modified in any manner. Prior to the
        handover of your mobile device for repair, you must:
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">(a)</td>
                <td>
                  delete all user content and data from your mobile device. You
                  acknowledge and accept that in carrying out the Screen Repair,
                  all mobile device data and user content will be deleted and
                  the mobile device will be reset to its factory default
                  settings;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(b)</td>
                <td>
                  disable all personal device locks (including FindMyiPhone) on
                  your mobile device; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  remove any accessories, stylus, SIM cards, SD cards or screen
                  protectors from your mobile device and must not provide any of
                  these to us.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>7.</TextMedium>	Your Screen Repair comes with a free 12 
        months warranty for any manufacturing malfunctions or defects that starts 
        from the date of return of your mobile device to you. A Screen Repair may 
        contain original or non-original manufacturer parts and any issues caused 
        by this Screen Repair may affect your manufacturer’s warranty coverage. 
        Manufacturing malfunctions or defects does not include battery degradation.
        <br />
        <br />
        <TextMedium>8.</TextMedium> You may only make 1 request for a Screen
        Repair in any rolling 12 months period, with such period commencing from
        the time of your first request for a Screen Repair.
        <br />
        <br />
        <TextMedium>9.</TextMedium> You have provided your consent for the
        collection, use or disclosure of your personal data in connection with
        the Screen Repair in accordance with M1’s prevailing published at{" "}
        <a
          href="https://www.m1.com.sg/data-protection"
          target="_blank"
          className="underline text-blue-700"
          rel="noopener noreferrer"
        >
          https://www.m1.com.sg/data-protection
        </a>
        .
        <br />
        <br />
        <TextMedium>10.</TextMedium> You acknowledge that this Screen Repair
        service is separate from M1 FoneCare+. The Repair Fee payable is in
        respect of the Screen Repair only and is not made in connection with
        your subscription to M1 FoneCare+.
        <br />
        <br />
        <TextMedium>11.</TextMedium> You must not provide us with any accessory
        for your mobile device when requesting a Screen Repair.
        <br />
        <br />
        <TextMedium>12.</TextMedium> M1 and Asurion shall not be liable for:
        <br />
        <div className="pl-4">
          <table className="border-0">
            <tbody>
              <tr>
                <td className="align-top pr-2">(a)</td>
                <td>
                  any loss of data and/or user content on your mobile device and
                  any misuse of data and/or user content on your mobile device
                  arising from your failure to delete the same;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(b)</td>
                <td>
                  any loss associated with timing for collection of your mobile
                  device or return of your mobile device following a Screen
                  Repair;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  any damage sustained to your mobile device arising from the
                  inspection and/or diagnostics of your mobile device or the
                  Screen Repair service generally;
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  any loss or damage to any accessory, stylus, SIM card or SD
                  card; and
                </td>
              </tr>
              <tr>
                <td className="align-top pr-2">(c)</td>
                <td>
                  any damage sustained to your mobile device in transit from
                  time of collection from you until the return of the mobile
                  device to you.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <TextMedium>13.</TextMedium> These terms and conditions, the service
        and/or price may be revised from time to time and your use of the
        service shall constitute your acceptance of these terms and conditions
        and their revisions.
        <br />
        <br />
        <TextMedium>14.</TextMedium> M1’s General Terms and Conditions available
        at m1.com.sg which may be modified by M1 at any time in its sole
        discretion, are deemed incorporated herein and shall apply. In the event
        of any conflict or inconsistency between any terms contained in the
        Terms and Conditions of the Screen Repair Service and M1’s General Terms
        and Conditions, these terms and conditions will prevail.
        <br />
        <br />
        <TextMedium>15.</TextMedium> Where this site contains links to other
        websites, webpages, resources, or mobile services which are proprietary
        to third parties, other users, advertisers or sponsors, such websites,
        webpages, resources and mobile services are provided for your
        information only and you access them at your own risk. M1 is not liable
        or responsible for the content or operation of third party websites,
        webpages, resources or mobile services. You should read any applicable
        terms and conditions and privacy policies.
        <br />
        <br />
      </>
    ),
  },
];
