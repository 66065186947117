import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

let initialState = {
  selectedStore: {},
  selectedAppointmentDate: "",
  selectedAppointmentSlot: "",
  stores: [],
  appointments: [],
  updateAppointment: {},
};

const walkInReducer = createSlice({
  name: "walkIn",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setSelectedStore(state, action) {
      state.selectedStore = action.payload;
    },
    setSelectedAppointmentDateTime(state, action) {
      state.selectedAppointmentDate = action.payload.date;
      state.selectedAppointmentSlot = action.payload.slot;
    },

    callGetStoresApi(state, action) {
      state.stores = action.payload;
    },
    callGetStoresApiSuccess(state, action) {
      console.log("callGetStoresApiSuccess", action.payload)
      let newPayload = null;
      if (action.payload && 
        action.payload.ASPStoresResponse && 
        action.payload.ASPStoresResponse?.length &&
        action.payload.ASPStoresResponse[0]?.ServiceProviderId) {
        let arr = []
        for(let store of action.payload.ASPStoresResponse) {
          let storeObj = {
            SERVICE_PROVIDER_ID: store.ServiceProviderId,
            SERVICE_PROVIDER_NAME: store.RepairStoreName,
            SERVICE_PROVIDER_LOCATION: store.Location,
            SERVICE_PROVIDER_CODE: store.RepairStoreCode,
            ADDRESS_ID: store.AddressId,
            ADDRESS_LINE_1: store.AddressLine1,
            ADDRESS_LINE_2: store.AddressLine2,
            ADDRESS_LINE_3: store.AddressLine3,
            CITY_NAME: store.City,
            STATE_PROVINCE_NAME: store.State,
            COUNTRY_NAME: store.Country,
            POSTAL_CODE: store.PostalCode,
            LATITUDE: store.Lattitude,
            LONGITUDE: store.Longitude
          }
          arr.push(storeObj)
        }
        newPayload = {
          ASPStoresResponse: [...arr]
        }
      }
      state.stores = newPayload ?? action.payload;
    },
    callGetStoresApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetAppointmentsApi(state, action) {
      state.appointments = action.payload;
    },
    callGetAppointmentsApiSuccess(state, action) {
      console.log("callGetAppointmentsApiSuccess", action.payload)
      let newPayload = null;
      if (action.payload && 
        action.payload.AppointmentSlotsResponse && 
        action.payload.AppointmentSlotsResponse?.availabilityData) {
        let arr = []
        for(let appointment of action.payload.AppointmentSlotsResponse.availabilityData) {  
          let slotArr = []
          for(let slot of appointment.startTime) {
            slotArr.push(moment(slot, "h:mma").format("HH:mm:ss"))
          }    
          arr.push({
            AvailableDate: appointment.date,
            TimeSlots: [...slotArr]
          })
        }
        newPayload = {
          AppointmentSlotsResponse: [...arr]
        }
      } else {
        newPayload = {...action.payload}
      }
      state.appointments = newPayload;
    },
    callGetAppointmentsApiFailure(state, action) {
      state.error = action.payload;
    },
    callUpdateAppointmentApi(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiSuccess(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = walkInReducer;
export const {
  resetStore,
  setSelectedStore,
  setSelectedAppointmentDateTime,
  callGetStoresApi,
  callGetAppointmentsApi,
  callUpdateAppointmentApi,
} = actions;
export default reducer;
