import { API, graphqlOperation } from "aws-amplify";

import { UpdateVisitorMutation } from "./Queries";
import { setVisitor } from "./state/reducers";

const updateVisitor = (updatedVisitorObj) => {
  const updateVisitor = async (visitor) => {
    return API.graphql(
      graphqlOperation(UpdateVisitorMutation, { input: visitor })
    );
  };

  return async (dispatch, getStore) => {
    const visitor = getStore().chat.visitor;
    const { visitorId } = visitor;
    const updatedVisitorData = {
      visitorId,
      ...updatedVisitorObj,
      lastUpdatedTime: new Date(),
    };
    await updateVisitor(updatedVisitorData);
    dispatch(setVisitor({ ...visitor, ...updatedVisitorObj }));
    return;
  };
};

export default updateVisitor;
