import { useState } from "react";
import { v4 as uuid } from "uuid";

import TwilioApis from "./TwilipApis";
import ChatApis from "./ChatApis";
import { CHAT_STATUS, AGENT_STATUS } from "./constants";
import { isEmpty } from "../../../helpers/formatUtils";
import CONSTANTS from "../../../helpers/constants";
import { useSelector } from "react-redux";

const useChat = ({
  visitorDetails,
  handleError,
  handleIncomingMessages,
  resumeFlow,
}) => {
  const [Subscriptions, setSubscriptions] = useState({});
  const [ConversationId, setConversationId] = useState("");
  const [VisitorDetails] = useState(visitorDetails);
  const [ChatStatus, setChatStatus] = useState("");
  const [chatRequestId, setChatRequestId] = useState("");
  const { chatReason } = useSelector((state) => state.chat);
  const { InteractionId : interactionId } = useSelector((state) => state.session.interactionData.Interaction);
  const srDetails = useSelector((state) => state.serviceRequest.serviceRequestDetails);

  const getUniqueId = () => uuid().replace(/-/g, "").toUpperCase();
  const isMobileView = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase()
  );

  const subscribeToNewConv = async (chatRequestId) => {
    const subscription = await ChatApis.subscribeToNewConversation(
      chatRequestId
    );
    setSubscriptions({
      ...Subscriptions,
      NewConversation: subscription.subscribe({
        next: (data) =>
          handleSubscriptions(
            data.value.data.onCreateConversation.conversationId,
            chatRequestId
          ),
        error: handleError ? handleError("NEW_CONV") : () => {},
      }),
    });
  };

  const handleSubscriptions = async (conversationId, requestId) => {
    setConversationId(conversationId);
    const endChatSubscription = ChatApis.subscribeToEndChat(requestId);
    setSubscriptions({
      ...Subscriptions,
      EndChat: endChatSubscription.subscribe({
        next: (data) => {
          handleEndChatByAgent(data.value.data.onUpdateChatRequest);
        },
        error: handleError ? handleError("END_CHAT") : () => {},
      }),
    });

    const newMessageSubscription = ChatApis.subscribeToNewMessages(
      conversationId
    );
    setSubscriptions({
      ...Subscriptions,
      NewMessage: newMessageSubscription.subscribe({
        next: (data) => handleAgentMessage(data.value.data.onCreateMessage),
        error: handleError ? handleError("NEW_MESSAGE") : () => {},
      }),
    });

    const subscribeToAgentTyping = ChatApis.subscribeToAgentTyping(
      conversationId
    );
    setSubscriptions({
      ...Subscriptions,
      AgentTyping: subscribeToAgentTyping.subscribe({
        next: (data) => handleAgentTyping(data.value.data.onAgentTyping),
        error: handleError ? handleError("AGENT_TYPING") : () => {},
      }),
    });

    //chat is connected here
    setChatStatus(CHAT_STATUS.CONNECTED);
    await ChatApis.createChatAudit({
      requestId: requestId,
      visitorId: VisitorDetails.visitorId,
      createdAt: new Date(),
      auditLog: `Chat Acknowledged`,
    });
  };

  //create visitor if vistor id is not provided
  //get twilio workspace token
  //init workspace
  //getRealtimeStatistics for agent availability
  //create chat request
  //create twilio task
  //update chat request with twilio sid
  //subscribe to new conversation
  //subscribe to end chat
  //subscribe to new message
  //subscribe to agent typing
  const startChat = async (attemptCount) => {
    const token = await TwilioApis.getWorkspaceToken();
    const workspace = await TwilioApis.initWorkspace(token.getTokenResponse.Token);
    const workersOnChat = await TwilioApis.getRealtimeStatistics(workspace);
    const uniqueId = getUniqueId();
    setChatRequestId(uniqueId);

    if (workersOnChat > 0) {
      console.log("workers are available, so initiating chat now...");
      const chatRequest = {
        requestId: uniqueId,
        visitorId: VisitorDetails.visitorId,
        visitorName: VisitorDetails.userName,
        interactionId: interactionId,
        customerId: "customerId",
        skillId: VisitorDetails.skillId,
        languageCode: VisitorDetails.languageCode,
        clientName: VisitorDetails.carrierName,
        requestStatus: "Initiated",
        chatReason: chatReason,
        requestType: CONSTANTS.REQUEST_TYPE,
        engagementType: CONSTANTS.ENGAGEMENT_TYPE,
        startTimestamp: new Date(),
        caseNumber: isEmpty(srDetails) ? "" : srDetails.CustomerCaseNumber,
        browserAgent: VisitorDetails.browserAgent,
        mdn: VisitorDetails.mdn, //
        requestChannel: isMobileView ? "Mobile Web" : "Web",
      };
      try {
        await ChatApis.createChatRequest(chatRequest);

        const [twilioTaskSid] = await Promise.all([
          TwilioApis.createTask(
            workspace,
            VisitorDetails.skillId,
            VisitorDetails.languageCode,
            VisitorDetails.visitorId,
            uniqueId,
            "AGENT"
          ),
          subscribeToNewConv(uniqueId),
        ]);

        console.log("twilio task::", twilioTaskSid);
        let updateChatTask = {
          requestId: uniqueId,
          taskId: twilioTaskSid,
        };
        await ChatApis.updateChatRequest(updateChatTask);

        setChatStatus(CHAT_STATUS.INITIATED);

        await ChatApis.createChatAudit({
          requestId: uniqueId,
          visitorId: VisitorDetails.visitorId,
          createdAt: new Date(),
          auditLog: `Chat Initiated`,
        });

        checkIfAgentHasAcceptedTheChat(workspace, twilioTaskSid);

        return CHAT_STATUS.INITIATED;
      } catch (e) {
        console.log(e);
        // allow user to retry
        setChatStatus(CHAT_STATUS.CHAT_RETRY);
      }
    } else {
      if (attemptCount > 2) {
        setChatStatus(CHAT_STATUS.ABANDONED);
        await ChatApis.createChatAudit({
          requestId: uniqueId,
          visitorId: VisitorDetails.visitorId,
          createdAt: new Date(),
          auditLog: `Chat Abandoned`,
        });
      }
      return CHAT_STATUS.ABANDONED;
    }
  };

  const clearAll = () => {
    Object.keys(Subscriptions).forEach((s) => {
      console.log("s::", s);
      s && s.unsubscribe && s.unsubscribe();
    });
  };

  const handleAgentMessage = (message) => {
    if (message.source.toUpperCase() === "AGENT") {
      handleIncomingMessages(message);
    }
  };

  const handleEndChatByAgent = async (chatStatus) => {
    if (
      [CHAT_STATUS.ENDED, CHAT_STATUS.TRANSFERRED].includes(
        chatStatus.requestStatus.toUpperCase()
      )
    ) {
      console.log("chatStatus::", chatStatus);
      setChatStatus(CHAT_STATUS.AGENT_DISCONNECT);
      await ChatApis.createChatAudit({
        requestId: chatRequestId,
        visitorId: VisitorDetails.visitorId,
        createdAt: new Date(),
        auditLog: `${
          chatStatus.wrapUpCode === "Resolved"
            ? "Chat Resolved"
            : "Chat Not Resolved"
        }`,
      });
      resumeFlow(chatStatus.wrapUpCode);
    }
    return;
  };

  const handleAgentTyping = () => {};

  //end chat by user
  //clear all subscription and timers being used
  const disconnectChat = async () => {
    await ChatApis.createChatMessage({
      messageId: getUniqueId(),
      visitorId: VisitorDetails.visitorId,
      conversationId: ConversationId || null,
      messageType: "Plain Text",
      interactionType: "Assistance",
      sender: VisitorDetails.userName,
      source: "Info",
      content: "The user has ended the chat.",
      isSent: false,
      recipient: "agent",
      isActive: true,
      createdAt: new Date(),
    });

    //reset all state and clear subscription
    clearAll();

    setChatStatus(CHAT_STATUS.USER_DISCONNECT);
  };

  const checkIfAgentHasAcceptedTheChat = async (workspace, taskSid) => {
    let status = "";
    const checkStatusOnInterval = setInterval(async () => {
      if (isEmpty(status) || status === AGENT_STATUS.RESERVED) {
        // Status can be reserved, assigned or completed
        status = await TwilioApis.fetchTask(workspace, taskSid);
      } else if (status === AGENT_STATUS.CANCELED) {
        setChatStatus(CHAT_STATUS.AGENT_BUSY);
        clearInterval(checkStatusOnInterval);
      } else {
        clearInterval(checkStatusOnInterval);
      }
    }, 5000);
  };

  // return init, startChat, endChat
  return { startChat, disconnectChat, ChatStatus, ConversationId };
};
//TODO: user disconnected message
//TODO: agent message handle
export default useChat;
