import React from "react";
import PropTypes from "prop-types";

const Loader = ({ className }) => {
  return <div className={`loader ${className}`}></div>;
};

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
