import { setTroubleshootAnswer } from "../reducers/troubleshootingReducer";
import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
} from "../../../../helpers/constants";
import {
  clearApiProgress,
  updateApiProgress,
  updateFlowProgress,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";

export const submitTroubleshootAnswer = (
  question,
  status,
  key,
  cancelReason
) => async (dispatch, getStore) => {
  const isConfirm = status === "Confirm";
  let showComponent = "";
  let module = "TROUBLESHOOT";

  if (!isConfirm) {
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", status),
        createMessage("TEXT", "system", {
          key: "SystemMessage.UnableToProcessRequest",
        }),
      ])
    );
    dispatch(confirmToCancelRequest("", cancelReason, true));
    return;
  }

  switch (key) {
    case "CameraWorking":
      showComponent = "PowerOn";
      break;
    case "PowerOn":
      showComponent = "LiquidExposure";
      break;
    case "LiquidExposure":
      showComponent = "PhysicalDamage";
      break;
    case "PhysicalDamage":
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.TERMS_N_CONDITIONS.title,
          PROGRESS_DATA.TERMS_N_CONDITIONS.count,
          initial
        )
      );
      module = "TERMS_AND_CONDITIONS";
      await dispatch(
        updateApiProgress(
          API_PROGRESS.TROUBLESHOOT,
          40,
          COMPONENTS.TROUBLESHOOT,
          0
        )
      );
      await sleep(1000);
      await dispatch(
        updateApiProgress(
          API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TROUBLESHOOT,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());
      break;
    default:
      showComponent = "CameraWorking";
      break;
  }
  dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));
  dispatch(saveTroubleshootAnswer(key, isConfirm, question));
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", status),
      createMessage(module, "system", { showComponent }),
    ])
  );
};

const saveTroubleshootAnswer = (key, value, question) => (dispatch) => {
  dispatch({
    type: setTroubleshootAnswer.toString(),
    payload: { key, value, question },
  });
};
