import React, { useState } from "react";
import { TextBold, TextMedium, TextRegular } from "../../../base/BaseText";
import MapContainer from "./MapContainer";
// import { Trans } from "../../../helpers/languageUtils";
import { Trans, useTranslation } from "react-i18next";

const RepairCenter = ({ asp, saveASP, disabled }) => {
  const [showMap, setShowMap] = useState(false);

  const toggleMapVisibility = () => {
    setShowMap(!showMap);
  };

  const handleASPSelection = (e) => {
    saveASP(asp);
  };

  return (
    <div className="p-4 border border-t-0 border-r-0 border-l-0">
      <div>
        <input
          type="radio"
          name="radio"
          value={asp.aspId}
          disabled={disabled}
          onChange={(e) => handleASPSelection(e)}
        />

        <div className="ml-4 inline-block text-xl">
          <TextBold className="ais_current-form-asp-name">
          {asp.SERVICE_PROVIDER_NAME && asp.SERVICE_PROVIDER_NAME.toUpperCase() === "TLC" ? "Asurion Tech Hub" : asp.SERVICE_PROVIDER_NAME}
          </TextBold>
        </div>
        {/*asp.LATITUDE && asp.LONGITUDE &&
          <div
            className="text-xl ml-10 inline-block text-srgreen-secondary cursor-pointer"
            onClick={toggleMapVisibility}
            disabled={disabled}
          >
            <Trans
              i18nKey={`${showMap
                  ? "WalkIn.RepairCenter.hideMap"
                  : "WalkIn.RepairCenter.mapButton"
                }`}
            >
              <TextMedium></TextMedium>
            </Trans>
          </div>
        */}        
      </div>
      <div className="text-xl mt-4 ml-8">
        {/* <TextRegular>{asp.SERVICE_PROVIDER_NAME}</TextRegular> */}
        {/* <TextRegular>{`${asp.ADDRESS_LINE_1} ${asp.ADDRESS_LINE_2 || ""} ${asp.ADDRESS_LINE_3 || ""} ${asp.CITY_NAME} ${asp.POSTAL_CODE} ${asp.STATE_PROVINCE_NAME ?? ""}`}</TextRegular> */}
        <TextRegular>{`${asp.ADDRESS_LINE_1} ${asp.ADDRESS_LINE_2 || ""} ${asp.ADDRESS_LINE_3 || ""} ${asp.CITY_NAME} ${asp.POSTAL_CODE}`}</TextRegular>
      </div>
      {/* {showMap && ( */}
        <div className={`mt-3 ml-8 w-90% ${showMap ? "" : "hidden"}`}>
          <MapContainer latitude={asp.LATITUDE} longitude={asp.LONGITUDE} />
        </div>
      {/* )} */}
    </div>
  );
};

export default RepairCenter;
