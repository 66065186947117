import { callUpdateContactsApi } from "../reducers/contactsReducer";

export const updateContactDetails = (
  cacheId,
  serviceRequestId,
  contactNo = "",
  emailId = "",
) => ({
  type: callUpdateContactsApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/accountadministration/v1/contactpoints",
    method: "POST",
    body: {
      CreateContactPointParameters: {
        SessionId: cacheId,
        ServiceRequestId: serviceRequestId,
        ContactPoint: {
          PhoneNumber: contactNo,
          EmailAddress: emailId,
        },
      },
    },
  },
});
