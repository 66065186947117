import {setJourneyMessages} from "./../reducers/journeyMessagesReducer"; //action

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const addJourneyMessages = (messages) => async (dispatch) => {
    let msgArr = Array.isArray(messages) ? messages : [messages];

    for(let msg of msgArr) {
        //await sleep(msg.delay || 500);
        dispatch(setJourneyMessages(msg));
        await sleep(msg.delay || 500);
    }
}