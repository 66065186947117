const ENQUIRY_OPTIONS = {
  Options: [
    {
      name: "CreateNewRequest",
      label: "Create a new Screen Repair Request",
      options: [
        {
          name: "NewRequest",
          label: "New Screen Repair Request",
        },
      ],
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Screen Repair Request",
    },
    {
      name: "ChangeSchedule",
      label: "Change Screen Repair Schedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel Screen Repair Request",
    },
    {
      name: "GeneralEnquiry",
      label: "General Enquiry",
    },
  ],
};

export const EnumEnquiryModules = {
  NewRequest: "NewRequest",
  InWarrantyRequest: "In-WarrantyRequest",
  ResumeRequest: "ResumeRequest",
  ChangeSchedule: "ChangeSchedule",
  CancelRequest: "CancelRequest",
  GeneralEnquiry: "GeneralEnquiry",
  CreateNewRequest: "CreateNewRequest",
};

export const INCIDENT_SCREEN_REPAIR = "Screen Repair";
export const INCIDENT_SCREEN_REPAIR_WARRANTY = "Screen Repair Warranty";

export default ENQUIRY_OPTIONS;
