import { addJourneyMessages, sleep } from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { PROGRESS_DATA, ACTIVITY, API_PROGRESS, COMPONENTS } from "../../../../helpers/constants";
import { clearApiProgress, serviceUnavailable, updateApiProgress, updateFlowProgress } from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { isEmpty } from "../../../../helpers/formatUtils";
import { getFulFillmentApi, processIncidentApi, setFulFillmentApi } from "../actions/actions";
import { getServiceFee } from "../actions/paymentApi";
import { initializePickupSchedule } from "./scheduleOperator";
import { INCIDENT_SCREEN_REPAIR, INCIDENT_SCREEN_REPAIR_WARRANTY } from "../../../../components/EnquiryOptions/Constants";

export const submitUserAcceptanceOnTerms = (status) => async (
  dispatch,
  getStore
) => {
  const isCustomerAgreed = status === "Accept";
  let text = "",
    showComponent = "",
    module = "";
  let flowTitle, flowCount;
  
  if (isCustomerAgreed) {
    text = "I accept Terms & Conditions";

    const { selectedAsset } = getStore().claim.deviceConfirmation;
    const { CacheId } = getStore().session.sessionData.Configurations;
    const { triageDetails } = getStore().claim.troubleshoot;
    const { caseType } = getStore().journeyMessages;

    const { isDeviceAppleSamsung = false } = getStore().claim?.deviceConfirmation;

    console.log("termsAndConditionOperator isDeviceAppleSamsung", isDeviceAppleSamsung)

    const {
      ServiceRequestId,
      CustomerCaseId,
    } = getStore().serviceRequest.serviceRequestDetails;
    const screenRepairQuestions = triageDetails.concat({
      key: "TERMS_CHECKED",
      Ans: true,
    });

    await dispatch(
      updateApiProgress(
        API_PROGRESS.TROUBLESHOOT,
        40,
        COMPONENTS.TERMS_N_CONDITIONS
      )
    );

    const processIncidentResponse = await dispatch(
      processIncidentApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        caseType,
        selectedAsset,
        screenRepairQuestions
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(processIncidentResponse)) return;

    let {
      CustomerCase,
      CustomerCase: {
        Eligibility: {
          EligibilityOutcome = "",
        } = {},
      } = {},
    } = processIncidentResponse.CreateIncidentResults;

    if (
      EligibilityOutcome === "Rejected" ||
      EligibilityOutcome === "Void" ||
      EligibilityOutcome === "VOID"
    ) {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceNotEligible";
    } else if (CustomerCase.Holds.length > 0) {
      // device hold
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceHold";
    } else {
      let getFulFillmentApiResponse = await dispatch(
        getFulFillmentApi(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(getFulFillmentApiResponse)) return;

      let setFulFillmentApiResponse = await dispatch(
        setFulFillmentApi(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(setFulFillmentApiResponse)) return;

      // calling service fee api here
      // let encodedPerilString = encodeURIComponent(`\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\"`);      
      let encodedPerilString = encodeURIComponent(`\"${caseType.toUpperCase()}\"`);
      let serviceFeeResposnse = await dispatch(getServiceFee(CacheId, ServiceRequestId, encodedPerilString))
      .catch((err) => {
        console.log("err", err)
        dispatch(serviceUnavailable())
      });

      console.log("serviceFeeResposnse", serviceFeeResposnse)

      // await dispatch(initializePickupSchedule(isDeviceAppleSamsung));
      // showComponent = "confirmPickupAddress";
      // module = "PICKUP_AND_DELIVERY";

      // const initial = getStore().journeyMessages.flowProgress.percentage;
      // dispatch(
      //   updateFlowProgress(
      //     PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title,
      //     PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count,
      //     initial
      //   )
      // );
      
      module = "SELECT_REPAIR_OPTION";
      showComponent = "SelectRepairOption";

      flowTitle = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.title;
      flowCount = PROGRESS_DATA.SELECT_REPAIR_OPTIONS.count;

      await dispatch(
        updateApiProgress(
          API_PROGRESS.TROUBLESHOOT_SUCCESS,
          100,
          COMPONENTS.TERMS_N_CONDITIONS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      // dispatch(
      //   addJourneyMessages([
      //     createMessage(module, "system", {
      //       showComponent,
      //     }),
      //   ])
      // );

      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(updateFlowProgress(flowTitle, flowCount, initial));

      dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
    }
    await dispatch(
      updateApiProgress(
        API_PROGRESS.TROUBLESHOOT_SUCCESS,
        100,
        COMPONENTS.TERMS_N_CONDITIONS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
  } else {
    text = "I decline Terms & Conditions";
    dispatch(confirmToCancelRequest("", "T&C declined", true));
  }

  dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));
  if (!isEmpty(showComponent)) {
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
};
