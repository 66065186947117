import React from "react";
import PropTypes from "prop-types";

const BaseLink = ({ children, className, onClick, disabled, ...restProps }) => {
  return (
    <span
      {...(!disabled && (onClick = { onClick }))}
      className={`app-BaseLink${className ? ` ${className}` : ""}`}
      {...restProps}
    >
      {children}
    </span>
  );
};

BaseLink.defaultProps = {
  disabled: false,
  onClick: () => {}
};

BaseLink.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BaseLink;
