
import { API, graphqlOperation } from 'aws-amplify';

import {CreateMessageMutation} from "./Queries";

export const sendAppsyncMessage = (message) => {
    const createChatMessage = async (chatMessage) => {
        return API.graphql(graphqlOperation(CreateMessageMutation, { input: chatMessage }));
    }

    return async (dispatch, getStore) => {
        console.log("appsync message::", message);
        const {visitorId, userName} = getStore().chat.visitor;
        const conversationId = getStore().chat.conversationId;
        const appsyncMessage = {
            conversationId: conversationId || null, //for chat put conversation id
            visitorId: visitorId,
            messageId: message.messageId,
            sender: conversationId ? userName : message.author,  //for chat use customer name/userName
            source: message.source,
            content: message.content,
            recipient: conversationId ? "agent" : "user", //for chat put "agent"
            isSent: false,
            createdAt: new Date(),
            messageType: message.type,
            interactionType: conversationId ? "Assistance" : "Online",  //for chat put "Assistance"
            isActive: true
        }
        await createChatMessage(appsyncMessage);
        return;
    } 
}