// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
// Base Components
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";
// Icons
import CalendarCheckedIcon from "../../../images/icon-calendar-selected.svg";
// Operator
import { confirmationOfDateAndTime } from "../state/operators/scheduleOperator";
import { getFormmatedDate } from "../../../helpers/dateUtils";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";

const ConfirmDateTime = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);

  const isCampaignTimingEligible = useSelector(
    (state) => state.claim.schedule.isCampaignTimingEligible
  );
  const returnSLA = isCampaignTimingEligible ? "1-2 business days" : "2 business days"

  const whichUpper = isPickup ? "Pick-up" : "Return";
  const which = whichUpper.toLowerCase();
  const date = getFormmatedDate(
    isPickup
      ? scheduleData.selectedPickupDate
      : scheduleData.selectedDeliveryDate,
    "d MMM yyyy"
  );
  const slot = isPickup
    ? scheduleData.selectedPickupSlot
    : scheduleData.selectedDeliverySlot;

  const [disabled, setDisabled] = useState(false);
  const [dateStatus, setDateStatus] = useState("");

  const handleSubmitPickupDateTime = (status) => {
    setDisabled(true);
    setDateStatus(status);
    dispatch(confirmationOfDateAndTime(status, isPickup));
  };

  return (
    <BaseCard>
      <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center />
      <BaseLabel>
        <Trans i18nKey="ConfirmDateTime.Title">
          <TextLight>
            Please confirm the {{ which }} schedule
            <br /> of your device
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseTable title={t(`ConfirmDateTime.TableTitle.${whichUpper}`)} verticle>
        <BaseTR>
          <BaseTH>{`${whichUpper} Date`}</BaseTH>
          <BaseTD>{date}</BaseTD>
        </BaseTR>
        <BaseTR>
          <BaseTH>{`${whichUpper} Time`}</BaseTH>
          <BaseTD>{slot}</BaseTD>
        </BaseTR>
      </BaseTable>
      <BaseInformationBox>
        <Trans i18nKey="ConfirmDateTime.Information">
          <TextLight>
            Please note that the <TextMedium>Screen Repair service</TextMedium>{" "}
            will be completed in <TextMedium>{{returnSLA}}</TextMedium> from
            the date the device is picked up.<br/><br/>
            
          </TextLight>
        </Trans>
        {isPickup &&
          <span style={{ display: "block", color: "red" }}>
            <br/>
            <Trans i18nKey="ConfirmDateTime.Information2">
              <TextLight>
                * For Huawei and Xiaomi devices, pick-up is only
                available on Mon or Fri due to a change in repair center
                operating hours.
              </TextLight>
            </Trans>
          </span>
        }
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleSubmitPickupDateTime("Change")}
          disabled={disabled}
          clicked={dateStatus === "Change"}
        />
        <BaseButton
          text={t("KeepButton")}
          onClick={() => handleSubmitPickupDateTime("Keep")}
          disabled={disabled}
          clicked={dateStatus === "Keep"}
          className="px-16"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmDateTime.defaultProps = {
  isPickup: true,
};

export default ConfirmDateTime;
