import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import BaseIcon from "../BaseIcon/BaseIcon";

const BaseIconLabel = ({ icon, children, className, ...restProps }) => {
  const extraClasses = className ? className.split(" ") : [];

  return (
    <div
      className={classNames("app-BaseIconLabel", ...extraClasses)}
      {...restProps}
    >
      <div className="app-BaseIconLabel__icon">
        <BaseIcon className="p-0" icon={icon} name="icon" />
      </div>
      <div className="app-BaseIconLabel__content">{children}</div>
    </div>
  );
};

BaseIconLabel.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BaseIconLabel;
