import initializeApi from "./initializeApi";
import interactionApi from "./interactionApi";
import { addJourneyMessages } from "./../helpers/addJourneyMessages";
import createMessage from "./../helpers/createMessage";
import createVisitor from "../modules/Appsync/createVisitor";
import getBusinessHours from "./businessHoursApi";

export const initProcessFlow = () => async (dispatch, getStore) => {
   // const session = getStore().session;
   // const {CacheId, UserName = "User"} = session && session.sessionData && session.sessionData.Configurations;
    //call initialize api and interaction api
    let initializeResponse = await dispatch(initializeApi());
    const {CacheId, UserName = "User"} = initializeResponse.Configurations;
    await dispatch(interactionApi(CacheId, UserName));

    await dispatch(getBusinessHours());

    await dispatch(createVisitor());
    //configure appsync and twilio and then show journey message
    dispatch(
        addJourneyMessages([
          createMessage("WELCOME_TO_SERVICE", "system"),  
          createMessage("VALIDATION", "system", { showComponent: "mdn" })     
        ])
      );
}