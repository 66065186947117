import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import TrackingIcon from "../../../images/icon-tracking.svg";
import { TextLight } from "../../../base/BaseText";
import { redirectToPhoneNumber } from "../state/operators/scheduleOperator";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
import { getFormmatedDate } from "../../../helpers/dateUtils";

const ConfirmDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const { isAfterCutoffError } = useSelector((state) => state.claim.schedule);

  const tableHeaders = [
    { key: "pickupAddress", text: "Pick-up Address" },
    { key: "returnAddress", text: "Return Address" },
    { key: "pickupDateTime", text: "Pick-up Date/Time" },
    { key: "returnDateTime", text: "Return Date/Time" },
  ];

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const tableData = [
    {
      pickupAddress: getAddressText(scheduleData.selectedPickupAddress),
      returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      pickupDateTime: `${getFormmatedDate(
        scheduleData.selectedPickupDate,
        "d MMM yyyy"
      )} ${scheduleData.selectedPickupSlot}`,
      returnDateTime: `${getFormmatedDate(
        scheduleData.selectedDeliveryDate,
        "d MMM yyyy"
      )} ${scheduleData.selectedDeliverySlot}`,
    },
  ];

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmDetails = (status) => {
    setConfirmationStatus(status);
    setDisabled(true);
    dispatch(redirectToPhoneNumber(status, isAfterCutoffError));
  };

  return (
    <BaseCard>
      <BaseIcon icon={TrackingIcon} name="Tracking" center />
      <BaseLabel>
        <Trans i18nKey="ConfirmScheduleDetails.Title">
          <TextLight>
            Please confirm the details of
            <br /> pick-up and return for your device
          </TextLight>
        </Trans>
      </BaseLabel>
      <BaseTable title={t("ConfirmScheduleDetails.Table.Title")}>
        {tableHeaders.map((header) => (
          <BaseTR key={header.key}>
            <BaseTH>{header.text}</BaseTH>
            {tableData.map((data, i) => (
              <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
            ))}
          </BaseTR>
        ))}
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmDetails("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
          className="border-app-border focus:bg-app-border hover:bg-app-border"
        />
        <BaseButton
          text={t("ConfirmButton")}
          onClick={() => handleConfirmDetails("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmDetails;
