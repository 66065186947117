import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { initModuleTrigger } from "../../actions/initModuleTrigger";
import ENQUIRY_OPTIONS, { EnumEnquiryModules } from "./Constants";
import BaseLabel from "../../base/BaseLabel";
import BaseAccordian from "../../base/BaseAccordian/BaseAccordian";
import { TextLight } from "../../base/BaseText";
import { INCIDENT_PATH_TYPE } from "../../helpers/constants";
import moment from "moment";

const EnquiryOptions = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [disabled, setDisabled] = useState(false);
  const srType = useSelector((state) => state.serviceRequest.type);
  const { lastCompletedCase } = useSelector((state) => state.serviceRequest);
  const { ServiceRequestType, RepairRequest, Servicerequests = [] } = useSelector((state) => state.serviceRequest.determineIncidentDetails);
  const selectedAgreement = useSelector((state) => state.validation.agreement);
  const isEnterprise =
    selectedAgreement?.ClientAccountType && selectedAgreement?.ClientAccountType &&
    (selectedAgreement?.ClientAccountType.toLowerCase() === 'sme' ||
      selectedAgreement?.ClientAccountType.toLowerCase() === 'corporate');

  console.log("selectedAgreement", selectedAgreement)
  console.log("isEnterprise", isEnterprise)

  const disabledOptions = () => {
    if (isEnterprise) {
      return [EnumEnquiryModules.CreateNewRequest, EnumEnquiryModules.ResumeRequest, EnumEnquiryModules.ChangeSchedule, EnumEnquiryModules.CancelRequest];
    } else {
      switch (srType) {
        case INCIDENT_PATH_TYPE.START_SERVICE_REQUEST:
          return [EnumEnquiryModules.ResumeRequest, EnumEnquiryModules.ChangeSchedule, EnumEnquiryModules.CancelRequest];
        case INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST:
          if (RepairRequest && RepairRequest.RepairStatus !== "RESERVED") {
            return [EnumEnquiryModules.CreateNewRequest, EnumEnquiryModules.ResumeRequest];
          } else if (
            (RepairRequest && RepairRequest.RepairStatus === "RESERVED") ||
            (RepairRequest === undefined && (ServiceRequestType === "DEFAULT" || ServiceRequestType === "SCRNREPREQ"))
          ) {
            return [EnumEnquiryModules.ChangeSchedule];
          } else {
            return [EnumEnquiryModules.ResumeRequest, EnumEnquiryModules.ChangeSchedule];
          }
        case INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED:
          return [EnumEnquiryModules.CreateNewRequest, EnumEnquiryModules.ResumeRequest];
        default:
          return [];
      }
    }    
  };

  function handleClick(option) {
    setSelectedOption(option.name);
    dispatch(initModuleTrigger(option));
    setDisabled(true);
  }

  const inWarrantyFilterDecorator = (fn) => {
    return function () {
      let options = fn();
      if (Servicerequests.length < 1) {
        if (lastCompletedCase) {
          let lastCompletedCaseShipDate = lastCompletedCase?.ServiceRequests[0]?.ShippingOrder?.ShipmentDate || "";
          let monthsNowDiffShipDate = moment().diff(moment(lastCompletedCaseShipDate), "months", true);

          if (isNaN(monthsNowDiffShipDate) || monthsNowDiffShipDate > 12) {
            options.push(EnumEnquiryModules.InWarrantyRequest);
          }
        } else {
          options.push(EnumEnquiryModules.InWarrantyRequest);
        }
      }
      return options;
    };
  };

  return (
    <div className="app-EnquireyOptions my-12">
      <div className="mx-auto p-4 max-w-xl bg-srgray-septenary rounded-1r">
        <BaseLabel>
          <Trans i18nKey="EnquiryOptions.Title" components={{ light: <TextLight /> }} />
        </BaseLabel>
        <BaseAccordian
          options={ENQUIRY_OPTIONS.Options}
          disabledKeys={inWarrantyFilterDecorator(disabledOptions)()}
          onClick={handleClick}
          selected={selectedOption}
          disabled={disabled}
          hideDisabledKeys={isEnterprise}
        />
      </div>
    </div>
  );
};

export default EnquiryOptions;
