import axios from "axios";
import Config from "../modules/Appsync/Config";

export function getTwilioConfig() {
    let data;
    let carrier;
    let config;
  
    return new Promise((resolve, reject) => {
      getCachedStaticFile(
        Config.TaskService.baseUrlSEA,
        "client/twiliopercarrier.json",
        false
      )
        .then((result) => {
          data = JSON.parse(result);
          carrier = findObjectWithKey("client", "m1sur", data.configs);
          config = findObjectWithKey(
            "env",
            Config.WPAPIServer.env.toLowerCase(),
            carrier.envs
          );
          resolve(config);
        })
        .catch((error) => {
          resolve(config);
        });
    });
  }

export function getCachedStaticFile(url, name, getCached = true) {
  return new Promise((resolve, reject) => {
    let jsonString = sessionStorage.getItem(name);

    try {
      if (jsonString === null || !getCached) {
        //try SEA URL
        getStaticFile(`${url}/v1/static/file?name=${name}`)
          .then((result) => {
            jsonString = JSON.stringify(result.data);
            // cache in sessionStorage
            sessionStorage.setItem(name, jsonString);
            // resolve from static file (S3)
            resolve(jsonString);
          })
          .catch((error) => {
            // force resolve
            resolve(null);
          });
      } else {
        // resolve from sessionStorage
        resolve(jsonString);
      }
    } catch (error) {
      //
    }
  });
}

export async function getStaticFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: {},
      method: "GET",
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findObjectWithKey(key, value, objects, startsWith) {
  let object = null;
  let keyValue;

  if (objects != null) {
    for (let s = 0; s < objects.length; s++) {
      object = objects[s];
      keyValue = object[key];
      if (startsWith) {
        if (keyValue.indexOf(value) > -1) {
          break;
        }
      } else {
        if (keyValue != null && keyValue == value) {
          break;
        }
      }
      // reset to null
      object = null;
    }
  }
  return object;
}

export async function getSmartScanApiToken(mainMDN) {
  let source = axios.CancelToken.source();
  let result = "";
  let getTokenUrl = `${process.env.REACT_APP_FP_SLS_BASE_ENDPOINT
      }/api/v1/token?primaryMdn=${mainMDN}&carrier=m1`;

  await axios({
      method: "GET",
      url: getTokenUrl,
      cancelToken: source.token,
  })
      .then((resp) => {
          console.log("status/review response", resp);
          result = resp?.data?.apiToken;
      })
      .catch((error) => {
          console.log("status/review:", error);
          if (axios.isCancel(error)) {
              console.log(`request cancelled:${error.message}`);
          } else {
              console.log("other error:" + error.message);
          }
      });

  return result
}

export async function getBYODDeviceStatus(smartScanToken, mainMDN) {
  let isdCodes = ["65"];
  let result = null;

  let fetchMemberList = async () => {
      let isDone = false;

      let config = smartScanToken ? {
          headers: {
              'x-smartscan-api-authtoken': smartScanToken
          }
      } : {}

      for (let isdCode of isdCodes) {
          if (!isDone) {
              let getReviewStatusUrl = `${process.env.REACT_APP_FP_SLS_BASE_ENDPOINT
                  }/api/v1/status/review`;

              await axios.post(getReviewStatusUrl,
                  {
                      "primaryMdn": `${isdCode}${mainMDN}`,
                  },
                  config
              )
                  .then((resp) => {
                      console.log("status/review response", resp);
                      result = resp.data;
                      isDone = true;
                  })
                  .catch((error) => {
                      console.log("status/review:", error);
                  });
          }
      }
  }

  await fetchMemberList();

  return result && result.length ? result : null;
}