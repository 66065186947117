import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import tsConfig from "./troubleshootConfig";
import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";

const Troubleshoot = ({ code }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");

  const handleSubmit = (status) => {
    setDisabled(true);
    setStatus(status);
    // remove elements from text
    const question = t(`Troubleshoot.${code}.Title`).replace(/[<0><0/><1><1/>]/g, '').trim();
    dispatch(submitTroubleshootAnswer(question, status, code, `${t(`Troubleshoot.${code}.CancelReason`)}`));
  };

  return (
    <BaseCard>
      <BaseIcon icon={tsConfig[code].icon} name="Camera" center />
      <BaseLabel >
        <Trans i18nKey={`Troubleshoot.${code}.Title`}>{tsConfig[code].title}</Trans>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          className="pr-16 pl-16"
          text={t("NoButton")}
          onClick={() => handleSubmit("No")}
          disabled={disabled}
          clicked={status === "No"}
        />
        <BaseButton
          text={t("ConfirmButton")}
          onClick={() => handleSubmit("Confirm")}
          disabled={disabled}
          clicked={status === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

Troubleshoot.propTypes = {
  code: PropTypes.string.isRequired
};

export default Troubleshoot;
