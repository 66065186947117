import React from "react";
import PropTypes from "prop-types";
import BookAppointment from './BookAppointment';
import SelectRepairCenter from './SelectRepairCenter';
import ConfirmAppointment from "./ConfirmAppointment";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "BookAppointment" && <BookAppointment />}
      {showComponent === "SelectRepairCenter" && <SelectRepairCenter />}
      {showComponent === "ConfirmAppointment" && <ConfirmAppointment />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
