import {
  saveDeviceMakeAndModel,
  callVerifyImeiApi,
  saveIsDeviceAppleSamsung
} from "../reducers/deviceConfirmationReducer";
import { callGetFulFillmentApi, callProcessIncidentApi, callSetFulFillmentApi } from "../reducers/termsAndConditionsReducer";

export const setDeviceMakeAndModel = (deviceData, isAppleSamsung = false) => (dispatch) => {
  dispatch({ type: saveDeviceMakeAndModel.toString(), payload: deviceData });
  dispatch({ type: saveIsDeviceAppleSamsung.toString(), payload: isAppleSamsung });
};

export const verifyImeiApi = ({
  cacheId,
  assetInstance,
  imei,
  imeiVerificationFailedAttempt = 0,
}) => ({
  type: callVerifyImeiApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/incidentidentification/v1/verifyimei",
    method: "POST",
    body: {
      VerifyIMEIParameters: {
        IMEI: imei,
        IMEIVerificationFailedAttempt: imeiVerificationFailedAttempt,
        AssetInstance: assetInstance,
        SessionId: cacheId,
      },
    },
  },
});

export const processIncidentApi = (
  CacheId,
  CustomerCaseId,
  ServiceRequestId,
  caseType,
  selectedAsset,
  screenRepairQa
) => ({
  type: callProcessIncidentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v1/incident/create`,
    method: "POST",
    body: {
      ProcessIncidentParameters: {
        SessionId: CacheId,
        CustomerCaseId: CustomerCaseId,
        ServiceRequestId: ServiceRequestId,
        Incident: {
          FailureDescriptiveText: "",
          IncidentType: caseType,
        },
        AssetDetails: {
          AssetCatalogId: selectedAsset.AssetCatalog.AssetCatalogId,
          SerialNumber: selectedAsset.IMEI,
        },
        ScreenRepairQA: screenRepairQa,
      },
    },
  },
});

export const getFulFillmentApi = (
  CacheId,
  ServiceRequestId,
) => ({
  type: callGetFulFillmentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/getfulfillmentoptions`,
    method: "POST",
    body: {
      GetFulfillmentOptionParameters: {
        SessionId: CacheId,
        ServiceRequestId: ServiceRequestId,
      },
    },
  },
});

export const setFulFillmentApi = (
  CacheId,
  ServiceRequestId,
  FulfillmentOptionType = "PUR"
) => ({
  type: callSetFulFillmentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/fulfillmentoptions`,
    method: "POST",
    body: {
      SetFulfillmentOptionParameters: {
        SessionId: CacheId,
        FulfillmentOption: FulfillmentOptionType,
        ServiceRequestId: ServiceRequestId,
      }
    },
  },
});
