import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseIcon from "../../base/BaseIcon/BaseIcon";

import PhoneIcon from "../../images/icon-phone.svg";
import { validateMdn } from "./state/operators";
import { isEmpty } from "../../helpers/formatUtils";
import { FIELD_IDS } from "../../helpers/constants";

const ReVerifyMdn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleMdnChange = (value) => {
    if (!isNaN(value)) setMdn(value);
  };

  const submitMdn = () => {
    dispatch(validateMdn(mdn));
    setDisabled(true);
  };

  const shouldSubmit = disabled || isEmpty(mdn) || mdn.length !== 8;

  return (
    <BaseCard>
      <BaseIcon icon={PhoneIcon} name="Re-Verify MDN" center />
      <BaseLabel>{t("ReVerifyMdn.Title")}</BaseLabel>
      <BaseInput
        id={FIELD_IDS.MDN}
        prefix="+65"
        value={mdn}
        onChange={handleMdnChange}
        small
        maxLength={8}
        disabled={disabled}
        autoFocus
        onEnterPress={shouldSubmit ? () => {} : submitMdn}
      />
      <BaseButtonGroup>
        <BaseButton
          id={FIELD_IDS.SUBMIT_MDN}
          name="ReSubmit"
          text={t("SubmitButton")}
          onClick={submitMdn}
          clicked={disabled}
          disabled={shouldSubmit}
          focused
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ReVerifyMdn;
