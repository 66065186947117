import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import { changeAddressSchedule } from "../state/operators/scheduleOperator";
import AddressIcon from "../../../images/icon-address.svg";
import { isEmpty } from "../../../helpers/formatUtils";

const ChangeAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const which = isPickup ? "Pick-up" : "Return";

  const [disabled, setDisabled] = useState(false);
  const [unitNumber, setUnitNumber] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [blockNumber, setBlockNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const handlePostalCodeChange = (code) => {
    if (!isNaN(code.trim())) setPostalCode(code.trim());
  };

  const handleSubmit = () => {
    setDisabled(true);
    const address = {
      unitNumber: unitNumber.trim(),
      buildingName: buildingName.trim(),
      blockNumber: blockNumber.trim(),
      streetName: streetName.trim(),
      postalCode,
    };
    dispatch(changeAddressSchedule(address, isPickup));
  };

  const cancelChangingAddress = () => {
    dispatch(changeAddressSchedule(null, isPickup));
  };

  const shouldntSubmit =
    disabled ||
    isEmpty(unitNumber) ||
    isEmpty(blockNumber) ||
    isEmpty(streetName) ||
    isEmpty(postalCode);

  return (
    <BaseCard>
      <BaseIcon icon={AddressIcon} name="Tracking" center />
      <BaseLabel>
        <Trans i18nKey="ChangeAddress.Title">
          <TextLight>Enter New {{ which }} Address</TextLight>
        </Trans>
      </BaseLabel>
      <BaseInput
        className="mb-2"
        placeholder={t("ChangeAddress.Input.UnitNo")}
        value={unitNumber}
        onChange={setUnitNumber}
        disabled={disabled}
        full
        autoFocus
      />
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.BuildingName")}
        value={buildingName}
        onChange={setBuildingName}
        disabled={disabled}
        full
      />
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.BlockNo")}
        value={blockNumber}
        onChange={setBlockNumber}
        disabled={disabled}
        full
      />
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.StreetName")}
        value={streetName}
        onChange={setStreetName}
        disabled={disabled}
        full
      />
      <BaseInput
        className="mt-2"
        placeholder={t("ChangeAddress.Input.PostalCode")}
        value={postalCode}
        onChange={handlePostalCodeChange}
        disabled={disabled}
        full
        maxLength={6}
        onEnterPress={shouldntSubmit ? () => {} : handleSubmit}
      />
      {!isPickup && (
        <BaseInformationBox>
          <Trans i18nKey="ChangeAddress.Information">
            <TextLight>
              Please note that we’re unable to deliver to shopping malls, coffee
              shops, hawker centers, so kindly provide proper residential or
              office address here.
            </TextLight>
          </Trans>
        </BaseInformationBox>
      )}
      <BaseButtonGroup>
        <BaseButton
          text={t("CancelButton")}
          onClick={cancelChangingAddress}
          disabled={disabled}
          className="border-app-border focus:bg-app-border hover:bg-app-border"
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeAddress.defaultProps = {
  isPickup: true,
};

export default ChangeAddress;
