import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextBold, TextLight, TextRegular } from "../../../base/BaseText";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { isEmpty } from "../../../helpers/formatUtils";
// import { Trans, useTrans } from "../../../helpers/languageUtils";
import { Trans, useTranslation } from "react-i18next";
import {
  confirmationOfRepairCenter,
  displayRepairDetails,
  displayRepairOption,
} from "../state/operators/RepairOptionsOperator";
import RepairCenter from "./RepairCenter";
// import { CLIENT } from "../../../helpers/constants";

const SelectRepairCenter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  // const client = useSelector((state) => state.app.client);

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  let masterstoreList = useSelector(
    (state) => state.claim.walkIn.stores.ASPStoresResponse
  );
  let sgStoreList = masterstoreList.filter((store) => store.SERVICE_PROVIDER_CODE && store.SERVICE_PROVIDER_CODE.toLowerCase() === "tlc")

  const storeList = [...sgStoreList]

  const [selectedAsp, setSelectedAsp] = useState({});

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(confirmationOfRepairCenter(selectedAsp));
  };

  const goBack = () => {
    const textPrint = t("GoBackButton");
    setDisabled(true);
    if (isChangeSchedule) {
      dispatch(displayRepairDetails(textPrint));
    } else {
      dispatch(displayRepairOption(textPrint));
    }
  };

  const saveSelectedASP = (asp) => {
    setSelectedAsp(asp);
  };

  const displayStoreList = (asp, i) => {
    return (
      <RepairCenter
        key={i}
        asp={asp}
        saveASP={saveSelectedASP}
        disabled={disabled}
      />
    );
  };

  const shouldSubmit = disabled || isEmpty(selectedAsp) ;

  return (
    <BaseCard>
      <BaseLabel>
        <Trans i18nKey="WalkIn.RepairCenter.title">
          <TextBold>
            Select preferred Repair Centre
            <br /> <TextBold>location below</TextBold>
          </TextBold>
        </Trans>
      </BaseLabel>
      {storeList.length > 0 && storeList.map((e, i) => displayStoreList(e, i))}
      <BaseButtonGroup>
        <BaseButton
          text={t("GoBackButton")}
          onClick={goBack}
          disabled={disabled}
        />
        <BaseButton
          text={t("SubmitButton")}
          onClick={handleSubmit}
          clicked={disabled}
          disabled={shouldSubmit}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default SelectRepairCenter;
