import React from "react";
import PropTypes from "prop-types";
import ConfirmDateTime from "./ConfirmDateTime";
import ConfirmAddress from "./ConfirmAddress";
import ConfirmDetails from "./ConfirmDetails";
import ChangeDateTime from "./ChangeDateTime";
import ChangeAddress from "./ChangeAddress";
import IncorrectAddress from "./IncorrectAddress";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "confirmPickupDateTime" && <ConfirmDateTime />}
      {showComponent === "changePickupDateTime" && <ChangeDateTime />}
      {showComponent === "confirmPickupAddress" && <ConfirmAddress />}
      {showComponent === "changePickupAddress" && <ChangeAddress />}
      {showComponent === "incorrectPickupAddress" && <IncorrectAddress />}
      {showComponent === "confirmDeliveryDateTime" && <ConfirmDateTime isPickup={false} />}
      {showComponent === "confirmDeliveryAddress" && <ConfirmAddress isPickup={false} />}
      {showComponent === "changeDeliveryDateTime" && <ChangeDateTime isPickup={false} />}
      {showComponent === "changeDeliveryAddress" && <ChangeAddress isPickup={false} />}
      {showComponent === "incorrectDeliveryAddress" && <IncorrectAddress isPickup={false} />}
      {showComponent === "confirmDetails" && <ConfirmDetails />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
