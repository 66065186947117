import React from "react";
import TextMessage from "./../components/TextMessage";
import EnquiryOptions from "./EnquiryOptions/EnquiryOptions";
import TrackDelivery from "../modules/TrackDelivery";
import GeneralEnquiry from "../modules/RepairClaim/GeneralEnquiry";
import WelcomeToService from "./../components/WelcomeToService/WelcomeToService";
import Validation from "../modules/Validation";
import ApiProgress from "../components/ApiProgress";
import DeviceConfirmation from "../modules/RepairClaim/DeviceConfirmation/DeviceConfirmation";
import PickupAndDelivery from "../modules/RepairClaim/PickupAndDelivery";
import CancelRepair from "../modules/RepairClaim/CancelRepair";
import Troubleshooting from "../modules/RepairClaim/Troubleshooting";
import TermsAndConditions from "../modules/RepairClaim/TermsAndConditions/TermsAndConditions";
import ContactConfirmation from "../modules/RepairClaim/ContactConfirmation";
import Payment from "../modules/RepairClaim/Payment";
import ConfirmRepairDetails from "../modules/RepairClaim/ConfirmRepairDetails/ConfirmRepairDetails";
import ImportantNote from "../modules/RepairClaim/ImportantNote/ImportantNote";
import Nps from "../modules/RepairClaim/NPS";
import Chat from "../modules/Appsync/Chat";
import SelectRepairOption from "../modules/RepairClaim/SelectRepairOption/SelectRepairOption";
import WalkIn from "../modules/RepairClaim/WalkIn";

const mapCompToConst = {
  TEXT: TextMessage,
  ENQUIRY_OPTIONS: EnquiryOptions,
  TRACK_DELIVERY: TrackDelivery,
  GENERAL_ENQUIRY: GeneralEnquiry,
  WELCOME_TO_SERVICE: WelcomeToService,
  VALIDATION: Validation,
  API_PROGRESS: ApiProgress,
  DEVICE_CONFIRMATION: DeviceConfirmation,
  PICKUP_AND_DELIVERY: PickupAndDelivery,
  CANCEL_REPAIR: CancelRepair,
  TROUBLESHOOT: Troubleshooting,
  TERMS_AND_CONDITIONS: TermsAndConditions,
  CONTACT_CONFIRMATION: ContactConfirmation,
  PAYMENT: Payment,
  REPAIR_DETAILS: ConfirmRepairDetails,
  NPS: Nps,
  IMPORTANT_NOTE: ImportantNote,
  CHAT: Chat,
  SELECT_REPAIR_OPTION: SelectRepairOption,
  WALK_IN: WalkIn,
};

export { mapCompToConst };

export default function RenderMessageOfType({ message }) {
  //here we actually check message type
  if (!mapCompToConst[message.type]) return null;
  let Component = mapCompToConst[message.type];

  return <Component {...message} {...message.data} />;
}
