import { createSlice } from '@reduxjs/toolkit';

let initialState = {};

const sessionReducer = createSlice({
    name: 'sessionData',
    initialState,
    reducers: { //for naming convention, always use set as prefix to identify it as reducer
        resetStore() {
            return initialState;
        },
        callInitializeApi(state, action) {
            state.sessionData = action.payload || {};
        },
        callInitializeApiSuccess(state, action) {
            console.log(action.payload);
            state.sessionData = action.payload;
        },
        callInitializeApiFailure(state, action) {
            state.error = action.payload;
        },

        callInteractionApi(state, action) {
            state.interactionData = action.payload || {};
        },
        callInteractionApiSuccess(state, action) {
            console.log(action.payload);
            state.interactionData = action.payload;
        },
        callInteractionApiFailure(state, action) {
            state.error = action.payload;
        },

        callGetBusinessHoursApi(state, action) {
            state.businessHours = action.payload || {};
        },
        callGetBusinessHoursApiSuccess(state, action) {
            console.log(action.payload);
            const { GetOnlineBusinessHoursResponse: businessHours = [] } = action.payload;
            state.businessHours = businessHours.length > 0 ? businessHours[0] : {};
        },
        callGetBusinessHoursApiFailure(state, action) {
            state.error = action.payload;
        },

    }
});

const { actions, reducer } = sessionReducer;
export const {callInitializeApi, callInteractionApi, callGetBusinessHoursApi} = actions;
export default reducer;