import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import ContactConfig from "./contactConfirmationConfig";
import { cancelledContactUpdate, submitChangesInContactDetails } from "../state/operators/contactsOperator";
import { isEmpty } from "../../../helpers/formatUtils";
import { REGEX } from "../../../helpers/constants";

const ChangeContactDetails = ({ code }) => {
  const isMobile = code === "ChangeMobileNumber";
  const [disabled, setDisabled] = useState(false);
  const [cancelled, setCancelled] = useState("");
  const [contact, setContact] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setCancelled(false);
    setDisabled(true);
    dispatch(submitChangesInContactDetails(contact, isMobile));
  };

  const cancelChangeDetails = () => {
    setCancelled(true);
    dispatch(cancelledContactUpdate());
  };

  const handleChangeContact = (input) => {
    if (isMobile && isNaN(input)) {
      return;
    } else {
      setContact(input);
    }
  };

  const isInputValid = () => {
    if (isMobile) {
      return contact.length === 8;
    } else {
      return REGEX.Email.test(contact);
    }
  };

  const shouldntSubmit = disabled || isEmpty(contact) || !isInputValid();

  return (
    <BaseCard>
      <BaseIcon icon={ContactConfig[code].icon} name="Contact" center />
      <BaseLabel>
        <Trans i18nKey={`Contacts.${code}.Title`}>
          {ContactConfig[code].title}
        </Trans>
      </BaseLabel>
      <BaseInput
        placeholder={t(`Contacts.${code}.Placeholder`)}
        value={contact}
        onChange={(val) => handleChangeContact(val.trim())}
        maxLength={isMobile ? 8 : 100}
        medium
        autoFocus
        onEnterPress={shouldntSubmit ? () => {} : handleSubmit}
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("CancelButton")}
          clicked={cancelled}
          onClick={cancelChangeDetails}
          disabled={disabled}
          className="border-app-border focus:bg-app-border hover:bg-app-border"
        />
        <BaseButton
          text={t("SubmitButton")}
          disabled={shouldntSubmit}
          clicked={!cancelled}
          onClick={handleSubmit}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeContactDetails.defaultProps = {
  code: "ChangeMobileNumber",
};

ChangeContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ChangeContactDetails;
