import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const BaseInformationBox = ({ children, className, ...restProps }) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <div
      className={classNames(
        "app-BaseInformationBox",
        ...extraClasses
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

BaseInformationBox.propTypes = {
  className: PropTypes.string,
};

export default BaseInformationBox;
