import React from "react";

export const TextRegular = ({ children }) => {
  return <span className="font-DINPro-Regular">{children}</span>;
};

export const TextLight = ({ children }) => {
  return <span className="font-DINPro-Light">{children}</span>;
};

export const TextMedium = ({ children }) => {
  return <span className="font-DINPro-Medium">{children}</span>;
};

export const TextBold = ({ children }) => {
  return <span className="font-DINPro-Bold">{children}</span>;
};