import ReactDOMServer from "react-dom/server";
import { getComponentForStaticHtml } from "./../App";

import { mapCompToConst } from "./../components/RenderMessageOfType";

import { sendAppsyncMessage } from "./../modules/Appsync/sendAppsyncMessage";

const appsyncMessage = (store) => (next) => (action) => {
  const result = next(action);
  const Component =
    (action.payload && mapCompToConst[action.payload.type]) || null;

  if (
    !(action.payload && action.payload.messageId) ||
    (action.payload && action.payload.source.toUpperCase() === "AGENT") ||
    !Component
  ) {
    return result;
  }
  console.log("inside appsync middleware", action.payload.type);

  const message = action.payload;
  // Send only Text if it is User Message
  if (action.payload.source.toUpperCase() === "USER") {
    store.dispatch(sendAppsyncMessage({ ...message, content: message.data }));
  } else {
    let staticHTML = ReactDOMServer.renderToStaticMarkup(
      getComponentForStaticHtml(Component, message)
    );
    //console.log(staticHTML);
    staticHTML &&
      store.dispatch(sendAppsyncMessage({ ...message, content: staticHTML }));
  }
  return result;
};

export default appsyncMessage;
